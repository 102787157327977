export const localeTranslationsModule = {
  "locale": "en",
  "translations": {
    "app-name": "Editus",
    "app-logo": "/assets/img/logo-en.svg",
    "dialog.yes-button": "Yes",
    "dialog.no-button": "No",
    "dialog.ok-button": "OK",
    "dialog.delete-button": "Delete",
    "dialog.cancel-button": "Cancel",
    "dialog.understand-button": "Understand",
    "dialog.save-button": "Save",
    "dialog.next-button": "Next",
    "dialog.back-button": "Back",
    "dialog.cancel-download-button": "Cancel download",
    "app-bar.create-project-button": "New project",
    "app-bar.account-button": "Account",
    "app-bar.sign-in-button": "Sign in",
    "app-bar.my-projects-button": "My projects",
    "app-bar.image-generation-button": "AI images",
    "app-bar.admin-button": "Admin",
    "mobile-lock-text1": "Not supported on mobile devices",
    "mobile-lock-text2": "Please login from your computer",
    "app-bar.profile.menu.profile-button": "Settings",
    "app-bar.profile.menu.pricing-button": "Rates",
    "app-bar.profile.menu.dark-theme-button": "Dark theme",
    "app-bar.profile.menu.logout-button": "Logout",
    "app-bar.profile.menu.language-button": "Language",
    "app-bar.profile.menu.promocode-button": "Enter promo code",
    "app-bar.profile.menu.start-onboarding-button": "Show onboarding",
    "app-bar.profile.menu.knowledge-base-button": "Knowledge base",
    "app-bar.menu-mobile.close-button": "Close window",
    "payment.error.cant-get-url": "Can't get payment link",
    "components.select.no-selected": "Select",
    "components.select.error-selected": "Select a value from the drop-down list",
    "canvas.error": "Your browser does not support the canvas element.",
    "undefined": "",
    "welcome.header": "Metranpage has been updated!",
    "welcome.text": "Now you can edit any text styles, set up margins, manage headers, etc.",
    "welcome.button": "Discover updates",
    "common.copy-text": "Copy text",
    "common.text-copied-to-clipboard-success": "Text copied to the clipboard",
    "common.text-copied-to-clipboard-failed": "Error on copy text",
    "common.promocode-copied-to-clipboard-success": "Promocode copied to the clipboard",
    "common.promocode-copied-to-clipboard-failed": "Error on copy promocode",
    "maintenance.title1": "We are conducting technical maintenance on the server. Please try again later 🙏",
    "maintenance.title2": "With care for you,",
    "maintenance.title3": "Yours, Editus",
    "welcome.update-news-202404.header": "Metranpage has been updated!",
    "welcome.update-news-202404.text": "We have simplified monetization of the service, now it will be even easier to work! All current user balances have been recalculated and have become more profitable!<br><br><a href=\"https://metranpage.com/monetization_update\" target=\"_blank\">More info in blog</a>",
    "welcome.update-news-202404.button": "Go to tariffs",
    "welcome.update-ig-12-24.header": "Face Repetition in Metranpage!",
    "welcome.update-ig-12-24.text": "Create unique images with your face or faces of your loved ones! Just upload a photo, choose a style and describe the desired image – and get vivid and memorable generations!",
    "welcome.update-ig-12-24.button": "Try",
    "welcome.update-news-ig.header": "And now for the Covers!",
    "welcome.update-news-ig.text": "We have released new functionality, now you can generate images and create unique book covers. Use the intuitive interface to choose the style and design. Make every book unforgettable with Metranpage!",
    "welcome.update-news-ig.button": "Try it",
    "welcome.update-news-ig-2.header": "Metranpage has been updated!",
    "welcome.update-news-ig-2.text": " +20 new image generation styles, image enhancement and expansion, and an updated cover editor with new features.  <br><br>Try the new features right now! ",
    "welcome.update-news-ig-2.button": "Try it",
    "welcome.update-news-ig-3.header": "A new level of accuracy!",
    "welcome.update-news-ig-3.text": "We've updated the image generation mode for more accurate repetition of subjects. There are 20 new styles available to make your work even more diverse. <br><br>Open up new creative possibilities!",
    "welcome.update-news-ig-3.button": "Try it",
    "welcome.webinar-1.header": "Master class: how to make a cover \n<br>for your best-selling book? ",
    "welcome.webinar-1.today.text": "Free webinar today at 20:00 MSC.",
    "welcome.webinar-1.tomorrow.text": "Free webinar tomorrow at 20:00 MSC.",
    "welcome.webinar-1.list.1": "How clichés work in design",
    "welcome.webinar-1.list.2": "The influence of genre on the cover",
    "welcome.webinar-1.list.3": "Practice: creating a cover based on an abstract",
    "welcome.webinar-1.author-name": "Kir Vitkovski",
    "welcome.webinar-1.author-text": ", founder of Metranpage",
    "welcome.webinar-1.gift": "🎁 A gift for every participant!",
    "welcome.webinar-1.button": "Take part",
    "welcome.update-news-ig-4.header": "Meet the new Creative <br>generation mode!",
    "welcome.update-news-ig-4.text": " +34 new styles and improved quality <br>based on Midjourney. <br><br>New «Style Repeat» feature in Basic Mode: upload your images and generate pictures based on them!",
    "welcome.update-news-ig-4.button": "Try it",
    "premium-mark": "Premium",
    "new-mark": "New",
    "soon-mark": "Soon",
    "soon-text-notification": "This functionality will appear in the next updates",
    "common.pluralize.left": "{value, plural, zero {left} one {left} two {left} few {left} many {left} other {left} }",
    "common.pluralize.add": "{value, plural, zero {add} one {add} two {add} few {add} many {add} other {add} }",
    "common.select-component.not-selected": "Not selected",
    "admin.nav.cover-templates": "Cover templates",
    "admin.cover-templates.button.setDefault": "Set default",
    "cover.shadow": "Shadow",
    "cover.offset": "Offset",
    "cover.direction": "Direction",
    "cover.blur": "Blur",
    "cover.transparency": "Transparency",
    "cover.shadow-fill": "Shadow fill",
    "cover.shadow-color": "Shadow color",
    "user.profile.nav.main": "Main data",
    "user.profile.nav.notifications": "Notifications",
    "user.profile.nav.rewards": "Rewards",
    "user.profile.nav.password": "Password",
    "user.profile.nav.tariff-and-payments": "Service plan and payment",
    "user.profile.nav.contacts": "Email address",
    "user.profile.nav.logout": "Log out ",
    "user.profile.nav.developer-settings": "Developer settings",
    "user.profile.main.first-name": "First name",
    "user.profile.main.last-name": "Second name",
    "user.profile.main.role": "User profile",
    "user.profile.main.delete-account-confirmation": "Are you sure you want to delete an account?",
    "user.profile.main.delete-account-enter-confirmation": "To delete account enter phrase:",
    "user.profile.main.delete-account-confirmation-phrase": "Delete",
    "user.profile.main.save-changes": "Save changes",
    "user.profile.main.saved": "Saved",
    "user.profile.password.delete-profile-error": "Error delete profile",
    "user.profile.password.old-password": "Old password",
    "user.profile.password.new-password": "New password",
    "user.profile.password.new-password-repeat": "Repeat new password ",
    "user.profile.password.change-password": "Change password",
    "user.profile.password.wrong-old-password": "Your old password is incorrect",
    "user.profile.password.changed": "The pasword has been changed",
    "user.profile.contacts.email": "Email",
    "user.profile.contacts.save-changes": "Save changes ",
    "user.profile.contacts.verification-email-was-send": "Verification email has been sent to your email address ",
    "user.profile.contacts.email-not-verified": "Email is not verified",
    "user.profile.contacts.verify-email": "Send email",
    "user.profile.tariff-and-payments.nav-tabs.tariff": "Rate and payment",
    "user.profile.tariff-and-payments.nav-tabs.payments-info": "Payment information",
    "user.profile.tariff-and-payments.nav-tabs.payments-history": "Payment history",
    "user.profile.tariff-and-payments.delete-card-confirmation": "Are you sure you want to delete the bank card?",
    "user.profile.tariff-and-payments.card-select": "Select",
    "user.profile.tariff-and-payments.card-active": "Active",
    "user.profile.tariff-and-payments.add-card": "Add bank card",
    "user.profile.tariff-and-payments.add-card-error": "Add card error",
    "user.profile.tariff-and-payments.expire": "Expires",
    "user.profile.tariff-and-payments.left": "left",
    "user.profile.tariff-and-payments.upgrade-tariff": "Upgrade plan",
    "user.profile.tariff-and-payments.need-help": "Need any help?",
    "user.profile.tariff-and-payments.contact-support": "Chat with support",
    "user.profile.tariff-and-payments.trial-period-end": "The free trial is over",
    "user.profile.tariff-and-payments.trial-period": "of free trial",
    "user.profile.tariff-and-payments.connect-tariff": "Add service plan ",
    "user.profile.tariff-and-payments.tariff.ask-question": "Fill in your question ",
    "user.profile.tariff-and-payments.payment-history.date": "Date",
    "user.profile.tariff-and-payments.payment-history.description": "Payment purpose",
    "user.profile.tariff-and-payments.payment-history.details": "Detailed inquiry ",
    "user.profile.tariff-and-payments.payment-history.amount": "Price",
    "user.profile.tariff-and-payments.payment-history.status": "Status",
    "user.profile.tariff-and-payments.payment-history.status.created": "Created",
    "user.profile.tariff-and-payments.payment-history.status.completed": "Paid",
    "user.profile.tariff-and-payments.payment-history.status.success": "Paid",
    "user.profile.tariff-and-payments.payment-history.status.failed": "Error",
    "user.auth.login-title": "Sign in",
    "user.auth.divider-text": "Or",
    "user.auth.login.social.google": "Continue with Google",
    "user.auth.login.social.vk": "Continue with VK",
    "user.auth.registration-title": "Registration",
    "user.auth.restore-password-title": "Forgot your password?",
    "user.auth.restore-password.login-button": "Log in",
    "user.auth.new-password-title": "Account ",
    "user.auth.email-placeholder": "e-mail",
    "user.auth.username-placeholder": "username",
    "user.auth.firstname-placeholder": "first name",
    "user.auth.lastname-placeholder": "second name",
    "user.auth.password-placeholder": "Password",
    "user.auth.password-confirmation-placeholder": "Repeat password",
    "user.auth.role-placeholder": "select role",
    "user.auth.error-selected-role": "Select role",
    "user.auth.login-button": "Sign in",
    "user.auth.register-button": "Register",
    "user.auth.register-switch": "Register",
    "user.auth.login-switch": "Sign in",
    "user.auth.already-registered-hint": "Already have an account?",
    "user.auth.not-registered-hint": "Don't have an account yet?",
    "user.auth.wrong-creds-error": "Invalid email or password",
    "user.auth.generic-error": "An unknown error has occurred. Please try again later.",
    "user.auth.cant-register-error": "The user could not be registered. Please try again later.",
    "user.auth.email-not-unique-error": "Email should be unique",
    "user.auth.passwords-are-different-error": "Passwords are different",
    "user.auth.wrong-login-method-error": "This account uses different way to log in",
    "user.auth.restore-password-prompt": "Forgot your password?",
    "user.auth.restore-password-description": "To reset your password, please provide your email, and we will send you a new password link",
    "user.auth.restore-password-request-button": "Restore your password ",
    "user.auth.restore-password-back-button": "Go back",
    "user.auth.restore-email-sent-text": "The link to restore your passport has been sent via email",
    "user.auth.restore-pass-email-not-found-error": "User account was not found, please enter another email address",
    "user.auth.restore-cant-restore-for-social-error": "Cannot reset password, as this account is linked to social media profile",
    "user.auth.reset-password-description": "Please enter a new password for your account",
    "user.auth.change-password-button": "Change password",
    "user.auth.restore-pass-token-not-found-error": "Cannot change your password. Please request a new reset password link",
    "user.auth.field-required-error": "Fill in the field",
    "user.auth.login-registration.title": "Create an account",
    "user.auth.login-registration.description": "Try all the features of the app and interact with the community",
    "user.auth.login-registration.button": "Login / Registration",
    "user.auth.login-registration.modal.title": "Log in to your account",
    "user.auth.login-registration.modal.description": "To get access to all service features, <br>register or authorize in the system.",
    "user.auth.social-login.continue-with": "Continue with",
    "user.auth.cant-login-vk": "Failed to sign in via VK",
    "user.auth.cant-login-google": "Failed to sign in via Google",
    "user.auth.cant-login-one-time-token": "Failed to sign in",
    "user.auth.sign-in-with-google": "Sign in with Google",
    "user.auth.switch-language": "Switch to Russian version",
    "user.auth.agreement-part1": "I agree to",
    "user.auth.agreement-part2": "the offer and the data processing policy",
    "user.auth.agreement-url": "https://editus.ru/terms",
    "user.auth.agreement-error": "You need to agree before registration",
    "user.auth.promo-agree": "I want to subscribe to news",
    "user.profile.title": "Profile",
    "user.profile.edit-form1-header": "Account settings",
    "user.profile.edit-form2-header": "Change password",
    "user.profile.email-placeholder": "e-mail",
    "user.profile.firstname-placeholder": "your name",
    "user.profile.old-password-placeholder": "old password",
    "user.profile.new-password-placeholder": "New password",
    "user.profile.new-password-confirmation-placeholder": "New password repeat",
    "user.profile.default-language": "Browser default language",
    "user.profile.save-button": "Save",
    "user.profile.logout-button": "Logout",
    "user.profile.delete-account-button": "Delete my account",
    "user.profile.verify-alert1": "Please",
    "user.profile.verify-alert2": "verify",
    "user.profile.verify-alert3": "your email",
    "user.profile.updated-successfully": "Your account has been successfully updated",
    "user.profile.please-update-account-with-role": "Please specify your role at <a href=\"/account\" style=\"text-decoration: underline;\">account settings</a>, this will help us to provide best service",
    "user.profile.enable-browser-notifications-button": "Enable browser notifications",
    "user.profile.browser-notifications-accepted": "You have enabled notifications!",
    "user.verify-email": "Please verify your email",
    "user.verify-email-send": "Please check email for verification your account",
    "user.verify-email-success": "Email verified success",
    "user.verify-email-user-not-found-error": "User not found",
    "user.verify-email-token-not-correct-error": "Token not correct",
    "user.verify-email-token-not-expired-error": "Token expired",
    "user.verify-email-send-header": "Please verify your email ",
    "user.verify-email-send-body": "We have sent a confirmation email to {$email}. Please check your inbox.<br>\nDidn't find the letter? Check the Spam folder or click the button below to resend the email",
    "user.verify-email-send-button": "resend",
    "user.verify-email-send-timer1": "(after ",
    "user.verify-email-send-timer2": " sec.)",
    "user.auth.error.wrong-email-format": "Wrong email format",
    "user.profile.verify-email-not-verified": "Email not verified yet. ",
    "user.profile.verify-email-send-email": "Verify",
    "user.profile.save-email-after-changed": "Email has been changed, please save new email and verify it",
    "user.roles.publisher": "Publisher",
    "user.roles.author": "Author",
    "user.roles.designer": "Designer",
    "user.roles.student": "Student",
    "user.verify-error": "Verification error, please refresh the page",
    "user.profile.change-password-not-correct-error": "The password is incorrect",
    "user.profile.change-password-is-equal-to-current-error": "The password is already being used",
    "user.profile.notifications.title": "Your notifications",
    "user.profile.notifications.filter.all": "All",
    "user.profile.notifications.filter.important": "Important",
    "user.profile.notifications.filter.news": "News",
    "user.profile.notifications.filter.moderation": "Moderation",
    "user.profile.notifications.card.moderation-success.reward": "to your account.",
    "user.profile.notifications.card.image-moderation-success.title": "The image passed moderation",
    "user.profile.notifications.card.image-moderation-success.description-1": "Your",
    "user.profile.notifications.card.image-moderation-success.description-action": "image",
    "user.profile.notifications.card.image-moderation-success.description-2": "has been published in the community.",
    "user.profile.notifications.card.image-moderation-success.description-3": "You have been credited 10 tokens.",
    "user.profile.notifications.card.image-moderation-fail.title": "The image has not been moderated",
    "user.profile.notifications.card.image-moderation-fail.description-1": "Your",
    "user.profile.notifications.card.image-moderation-fail.description-action": "image",
    "user.profile.notifications.card.image-moderation-fail.description-2": "has not been published in the community for the following reason:",
    "user.profile.notifications.card.image-moderation-fail.description-without-reason": "has not been published in the community.",
    "user.profile.notifications.card.cover-moderation-success.title": "The cover passed moderation",
    "user.profile.notifications.card.cover-moderation-success.description-1": "Your",
    "user.profile.notifications.card.cover-moderation-success.description-action": "cover",
    "user.profile.notifications.card.cover-moderation-success.description-2": "has been published in the community.",
    "user.profile.notifications.card.cover-moderation-success.description-3": "You have been credited 10 tokens.",
    "user.profile.notifications.card.cover-moderation-fail.title": "The cover has not been moderated",
    "user.profile.notifications.card.cover-moderation-fail.description-1": "Your",
    "user.profile.notifications.card.cover-moderation-fail.description-action": "cover",
    "user.profile.notifications.card.cover-moderation-fail.description-2": "has not been published in the community for the following reason:",
    "user.profile.notifications.card.promocode-activate.title": "Congratulations! Promo code {$promocodeTitile} has been applied",
    "user.profile.notifications.card.promocode-activate.subscription.description-1": "Subscription to the tariff",
    "user.profile.notifications.card.promocode-activate.subscription.description-2": "for",
    "user.profile.notifications.card.promocode-activate.subscription.description-3": "is activated",
    "user.profile.notifications.card.promocode-activate.tokens.description": "have been credited to your balance.",
    "user.profile.notifications.card.promocode-activate.discount.description-1": "Hurry up and use it to take advantage of the best deals!",
    "user.profile.notifications.card.promocode-activate.discount.description-2": "Discount",
    "user.profile.notifications.card.promocode-activate.discount.description-3": "is valid until",
    "user.profile.notifications.card.promocode-expire.title": "to use the promo code",
    "user.profile.notifications.card.promocode-expire.description-1": "Last chance! Use your",
    "user.profile.notifications.card.promocode-expire.description-action": "promo code",
    "user.profile.notifications.card.promocode-expire.description-2": "until tomorrow so you don't miss out on the discount!",
    "user.profile.notifications.card.registration.title": "Welcome!",
    "user.profile.notifications.card.registration.description": "We are pleased to welcome you to the community of book professionals and book lovers. Now you have access to powerful tools for automatic layout of print and e-books.",
    "user.profile.notifications.card.email-verification.title": "Confirm email",
    "user.profile.notifications.card.email-verification.description-1": "Please confirm your",
    "user.profile.notifications.card.email-verification.description-action": "e-mail",
    "user.profile.notifications.card.email-verification.description-2": "to get started.",
    "user.profile.notifications.card.trial-period-activate.title-1": "You have access to",
    "user.profile.notifications.card.trial-period-activate.title-2": "trial period",
    "user.profile.notifications.card.trial-period-activate.description-1": "The trial period will end",
    "user.profile.notifications.card.trial-period-ending.title": "Until the end of the trial period",
    "user.profile.notifications.card.trial-period-ending.3-days-left.description-1": "Don't miss the opportunity to upgrade to a paid",
    "user.profile.notifications.card.trial-period-ending.3-days-left.description-2": "and continue working in the service without restrictions.",
    "user.profile.notifications.card.trial-period-ending.description-action": "tariff",
    "user.profile.notifications.card.trial-period-ending.description": "Trial period will end",
    "user.profile.notifications.card.trial-period-ending.1-day-left.description-1": "Your free trial period expires tomorrow. You will have access to only 1 project and limited access to the service functionality. Switch to a paid",
    "user.profile.notifications.card.trial-period-ending.1-day-left.description-2": "and enjoy all the features of the service.",
    "user.profile.notifications.card.subscription-activate.title-1": "You have activated the tariff plan",
    "user.profile.notifications.card.subscription-activate.title-2": "validity period",
    "user.profile.notifications.card.subscription-activate.description-1": "Subscription will expire",
    "user.profile.notifications.card.subscription-ending.title-1": "We have until the end of your subscription",
    "user.profile.notifications.card.subscription-ending.title-2": "remains",
    "user.profile.notifications.card.subscription-ending.description-1": "Please make sure you have enough funds on your card for your next payment.",
    "user.profile.notifications.card.subscription-ending.description-2": "Subscription will end",
    "user.profile.notifications.card.book-layout.preview.title-1": "Your book preview",
    "user.profile.notifications.card.book-layout.preview.title-2": "ready",
    "user.profile.notifications.card.book-layout.preview.description-1": "Book preview",
    "user.profile.notifications.card.book-layout.preview.description-2": "ready",
    "user.profile.notifications.card.book-layout.final.title-1": "The layout of your book",
    "user.profile.notifications.card.book-layout.final.title-2": "ready",
    "user.profile.notifications.card.book-layout.final.description-1": "Book layout",
    "user.profile.notifications.card.book-layout.final.description-2": "ready",
    "user.profile.notifications.card.book-without-cover.title-1": "Your book",
    "user.profile.notifications.card.book-without-cover.title-2": "has no cover",
    "user.profile.notifications.card.book-without-cover.description-action": "Create",
    "user.profile.notifications.card.book-without-cover.description": "right now!",
    "user.profile.notifications.card.low-balance.title": "Attention! You have ",
    "user.profile.notifications.card.low-balance.description-1": "Replenish",
    "user.profile.notifications.card.low-balance.description-action": "balance,",
    "user.profile.notifications.card.low-balance.description-2": "to continue using all functions of the service without restrictions.",
    "user.profile.notifications.card.connect-telegram.title": "Take 75 bonus tokens!",
    "user.profile.notifications.card.connect-telegram.description-1": "Don't miss your chance! Subscribe to our",
    "user.profile.notifications.card.connect-telegram.description-action": "Telegram channel",
    "user.profile.notifications.card.connect-telegram.description-2": "right now and get 75 bonus tokens as a gift.",
    "user.profile.notifications.card.connect-telegram.description-3": "Bonus tokens will help you to master all the features of our service faster and become a real master of layout and design!",
    "user.profile.notifications.card.add-tokens.title": "Tokens have been added",
    "user.profile.notifications.card.add-tokens.description-1": "to your balance",
    "user.profile.notifications.card.add-tokens.purpose.connect-telegram": "For subscribing to the Telegram channel",
    "user.profile.notifications.card.add-tokens.purpose.bonus": "As a bonus",
    "user.profile.notifications.card.like.title": "You've got a like",
    "user.profile.notifications.card.like.image.description-1": "Your",
    "user.profile.notifications.card.like.image.action": "image",
    "user.profile.notifications.card.like.image.description-2": "liked",
    "user.profile.notifications.card.like.cover.description-1": "Your",
    "user.profile.notifications.card.like.cover.action": "cover",
    "user.profile.notifications.card.like.cover.description-2": "liked",
    "user.profile.notifications.card.webinar.title": "How to make a cover for your bestselling book?",
    "user.profile.notifications.card.webinar.active.action": "Watch",
    "user.profile.notifications.card.webinar.active.date-today": "today",
    "user.profile.notifications.card.webinar.active.date-tomorrow": "tomorrow",
    "user.profile.notifications.card.webinar.active.description": "a free master class at 20:00 MSC",
    "user.profile.notifications.card.webinar.ended.description": "The webinar has ended",
    "user.profile.socials.telegram.modal.title-1": "Get",
    "user.profile.socials.telegram.modal.title-2": "for subscribing to our Telegram account",
    "user.profile.socials.telegram.modal.conditions-title": "Follow the simple steps:",
    "user.profile.socials.telegram.modal.check-title": "Verification will be available after you complete",
    "user.profile.socials.telegram.modal.check-subscription-to-channel": "Verify subscription",
    "user.profile.socials.telegram": "Telegram",
    "user.profile.socials.telegram.link-account": "Link a Telegram account",
    "user.profile.socials.telegram.subscription-to-channel": "Subscribe to the channel",
    "user.profile.socials.telegram.link-account.error": "Telegram connection error",
    "user.profile.socials.disconnect-account-confirmation.telegram": "Are you sure you want to untether Telegram?",
    "user.profile.socials.disconnect-account-confirmation.telegram.error": "An error occurred while disabling your Telegram account. Try again later",
    "user.profile.socials.telegram.token-expire-error": "Telegram account is not connected. The link is out of date. Try again",
    "user.profile.socials.telegram.account-already-connected-error": "The Telegram account is already connected",
    "user.profile.socials.telegram.account-already-connected-to-another-account-error": "The Telegram account is already connected to another account",
    "user.profile.socials.telegram-profile-connected": "Telegram account is connected",
    "user.profile.socials.telegram-profile-not-found-error": "Telegram account is not connected",
    "user.profile.socials.telegram.subscription-to-channel-verified": "Channel subscription confirmed",
    "user.profile.socials.telegram.check-subscription-to-channel-error": "There was an error checking subscription to the channel",
    "user.profile.socials.vk": "VKontakte",
    "user.profile.socials.vk.link-account": "Link VK profile",
    "user.profile.socials.not-connected": "Not connected",
    "user.profile.socials.connected": "Connected",
    "user.profile.socials.disconnect-button": "Detach from the profile",
    "user.profile.socials.disconnect-account-confirmation-phrase": "Delete",
    "user.profile.rewards-list.title": "Your notifications",
    "user.profile.rewards.title": "Accrual history",
    "user.profile.rewards.subscribe-to-telegram-channel": "Subscribe to Telegram",
    "user.profile.rewards.like.give": "Liked",
    "user.profile.rewards.like.take": "Like received",
    "user.profile.rewards.moderation-image": "Published image",
    "user.profile.rewards.moderation-cover": "Published cover",
    "user.profile.rewards.like.give.notification": "For liking you",
    "user.profile.reward-tasks.subscribe-to-telegram-channel.title": "Subscribe to our Telegram channel",
    "user.profile.reward-tasks.subscribe-to-telegram-channel.task-1": "Link your Telegram account",
    "user.profile.reward-tasks.subscribe-to-telegram-channel.task-1-action": "in your profile",
    "user.profile.reward-tasks.subscribe-to-telegram-channel.task-2": "Subscribe",
    "user.profile.reward-tasks.subscribe-to-telegram-channel.task-2-action": "Telegram channel",
    "user.profile.reward-tasks.subscribe-to-telegram-channel.button": "Check",
    "user.profile.reward-tasks.subscribe-to-telegram-channel.you-get-text": "and you'll get",
    "user.profile.reward-tasks.completed": "Completed",
    "user.profile.reward-tasks.daily.likes.give": "Reward for every one of your likes",
    "user.profile.reward-tasks.daily.likes.take": "Reward for every like you get",
    "user.profile.reward-tasks.daily.per-day": "per day",
    "books.error.cant-load-books": "Failed to upload the book list, please try again later",
    "books.error.cant-load-book": "Failed to upload your book ",
    "books.error.cant-create-book": "Failed to create the book, please try again later",
    "books.error.cant-edit-book": "Failed to save the changes, please try again later",
    "books.error.markup-create-error": "An error occurred while creating the layout. We are working on fixing a bug, please try again later.",
    "books.error.preview-create-error": "An error occurred while reviwing files. We are working on fixing a bug, please try again later.",
    "books.error.final-create-error": "An error occured while laying out a book. We are working on fixing a bug, please try again later.",
    "books.error.process-error": "An unexpected error occurred {$book-title} {$book-id}",
    "books.notification.book-saved": "Saved",
    "books.list.empty": "You don't have any projects yet",
    "books.error.cant-copy-book": "Failed to duplicate the book",
    "books.error.cant-delete-book": "Failed to delete the book",
    "books.breadcrumb.new-project": "New project",
    "books.breadcrumb.upload-step": "Upload",
    "books.breadcrumb.template-step": "Template",
    "books.breadcrumb.markup-step": "Markup",
    "books.breadcrumb.layout-step": "Layout",
    "books.breadcrumb.result-step": "Result",
    "books.breadcrumb.build-step": "Layout creation",
    "books.breadcrumbs.cover": "Cover",
    "books.list.title": "My projects",
    "books.list.hint": "While doing a new project, you create a publication draft with unique design or a template, for example, for a book series with common design. You can create and store many books for one project.",
    "books.list.edit-panel.general": "General",
    "books.list.edit-panel.additional": "Select language and type",
    "books.list.edit-panel.format": "Format",
    "books.list.edit-panel.edition-notice": "Edition notice",
    "books.book.status-prefix": "Status:",
    "books.book.status-docx": "layout creation",
    "books.book.status-preview": "preview creation",
    "books.book.status-final": "book creation",
    "books.book.status-final-ready": "the book is ready",
    "books.book.status-previews-ready": "preview is ready",
    "books.book.status-markup-ready": "styles are defined",
    "books.book.status-no-styles": "styles are undefined",
    "books.book.action-docx": "Creating a layout...",
    "books.book.action-preview": "Creating a layout...",
    "books.book.action-final": "Designing a book...",
    "books.book.type-title": "Select publication version",
    "books.book.type-book-title": "Book",
    "books.book.type-book-image": "./assets/img/book.png",
    "books.book.type-newspaper-title": "Newspaper (coming soon)",
    "books.book.type-newspaper-image": "./assets/img/newspaper-stroke.png",
    "books.book.type-booklet-title": "Brochure (coming soon)",
    "books.book.type-booklet-image": "./assets/img/booklet-stroke.png",
    "books.book.type-presentation-title": "Presentation (coming soon)",
    "books.book.type-presentation-image": "./assets/img/presentation-stroke.png",
    "books.book.error-cannot-set-type": "Cannot save book. Please try again later",
    "books.book.final-indd-link-title": "Source .indd files",
    "books.book.final-pdf-link-title": "Preview .pdf file",
    "books.book.final-pdf-for-print-link-title": "File for printing (.pdf) ",
    "books.book.final-epub-link-title": "Epub",
    "books.book.final-all-title": "Download all (.zip)",
    "books.book.download-cover": "Download cover image (.png)",
    "books.book.create-button": "New project",
    "books.book.download-button": "Download",
    "books.book.edit-button": "Edit",
    "books.book.edit-cover": "Edit cover",
    "books.book.edit-book-data": "Edit book info",
    "books.book.edit-book": "Edit book",
    "books.book.upload-book": "Upload book",
    "books.book.copy-book": "Duplicate",
    "books.book.delete-button": "Delete",
    "books.book.delete-dialog-confirmation": "Confirm action",
    "books.book.delete-dialog-title": "Do you really want to delete the project",
    "books.book.delete-project-dialog-button": "Delete project",
    "books.book.action-docx-title": "Marking up your document",
    "books.book.action-docx-description": "This will take some time",
    "books.book.action-preview-title": "Preview creation in progress",
    "books.book.action-preview-description": "This will take some time",
    "books.book.action-final-title": "Document creation in progress",
    "books.book.action-final-description": "This will take some time",
    "books.book.create-cover-title": "Create <br>cover",
    "books.book.print-settings.paper-type": "Paper",
    "books.book.print-settings.cover-type": "Cover",
    "books.book.print-settings.cover-lamination-type": "Cover lamination",
    "books.book.print-settings.print-chromaticity": "Chromaticity",
    "books.book.print-settings.print-run": "Print run",
    "books.book.print-settings.estimated-price": "Estimated price",
    "books.book.print-settings.button.request-price-calculation": "Request final price",
    "books.book.print-settings.price-calculation": "Price calculation",
    "books.book.print-settings.constraint-finalize-first": "In order to get an access to calulator, you should finalize the project first",
    "books.book.print-settings.constraint-pages": "Available for projects with at least 38 pages",
    "books.book.print-settings.constraint-size-format": "Cost estimation is available for projects with A5 page format only",
    "books.book.print-settings.constraint-format": "Available for print editions",
    "books.book.print-settings.placeholder.phone-number": "Phone number",
    "books.book.print-settings.placeholder.delivery-address": "Delivery address",
    "books.book.print-settings.button.request": "Send request",
    "books.book.print-settings.thanks": "Thank you!",
    "books.book.print-settings.request-explanation": "We will send you an email with calculation details soon",
    "books.book.print-settings.button.excellent": "Excellent",
    "books.book.print-settings.contact-form-header": "Precise calculation",
    "books.book.print-settings.redirect-button-title": "Go to print",
    "books.new-book.header": "New book",
    "books.new-book.genre": "Book genre",
    "books.new-book.create-button": "Create",
    "books.new-book.save-button": "Save",
    "books.new-book.cancel-button": "Cancel",
    "books.format.title": "Format",
    "books.format.square": "Square",
    "books.format.custom": "Random",
    "books.format.width-label": "Width mm.",
    "books.format.width-placeholder": "width",
    "books.format.height-label": "Height mm.",
    "books.format.height-placeholder": "height",
    "books.format.size-error": "Page size cannot be less than 52mm.",
    "books.format.vertical-format-error": "Please specify the vertical format of your project",
    "books.info.title-placeholder": "Book title",
    "books.info.author-placeholder": "Author",
    "books.info.year-placeholder": "Year",
    "books.info.city-placeholder": "City",
    "books.upload.sample-options": "You can create",
    "books.upload.drop-text1": "Drag and drop here",
    "books.upload.drop-text2": "or",
    "books.upload.drop-text2-upload": "upload",
    "books.upload.drop-text3": ".docx file",
    "books.upload.drop-text4": "or",
    "books.upload.drop-text5-select": "select",
    "books.upload.drop-text6": "previously uploaded file",
    "books.upload.process-hint": "Marking the text of the publication...\n",
    "books.upload.download-sample-text-button": "Download a sample text .docx",
    "books.upload.helper-title": "How to prepare the text for layout?",
    "books.upload.helper-title-hide-button": "Collapse",
    "books.upload.helper-advice1-b": "Only use standard styles",
    "books.upload.helper-advice1": "Don't create your own styles and don't rename existing ones. If you don't have the style you need, leave this part without the style, you can add it in the next step.",
    "books.upload.helper-advice2-b": "Don't apply the wraparound text effect",
    "books.upload.helper-advice2": "It is best to place pictures in separate lines in the highest resolution possible. ",
    "books.upload.helper-instruction-title": "Instructions",
    "books.upload.helper-instruction-subtitle": "To download images to Microsoft Word without losing quality\n",
    "books.upload.helper-step": "Step",
    "books.upload.helper-instruction-step-win1": "Go to parameters",
    "books.upload.helper-instruction-step-win1-image": "./assets/img/win1.png",
    "books.upload.helper-instruction-step-win2": "Under the \"Advanced\" tab, go to the \"Image Size and Quality\" section. Choose \"330 pixels per inch\".",
    "books.upload.helper-instruction-step-win2-image": "./assets/img/win2.png",
    "books.upload.helper-instruction-step-mac1": "Go to parameters",
    "books.upload.helper-instruction-step-mac1-image": "./assets/img/mac1.png",
    "books.upload.helper-instruction-step-mac2": "Press the \"Change\" button",
    "books.upload.helper-instruction-step-mac2-image": "./assets/img/mac2.png",
    "books.upload.helper-instruction-step-mac3": "There is no text in the layout",
    "books.upload.helper-instruction-step-mac3-image": "./assets/img/mac3.png",
    "books.upload.not-docx-error": "Please upload your manuscript in .docx format",
    "books.upload.cant-read-file-error": "The file could not be read. Please delete the table of content, output, decorative elements from your .docx file and try again. If your document contains grouped objects, please ungroup them. If the error occurs again, please contact support: zakaz@editus.ru",
    "books.upload.hint1.image": "/assets/images/upload-no-scan.svg",
    "books.upload.hint1.title": "Do not use scanned pages in document",
    "books.upload.hint1.description": "We can't define scanned content and make a layout. The text should be added in editable format",
    "books.upload.hint2.image": "/assets/images/upload-no-toc.svg",
    "books.upload.hint2.title": "Remove table of contents",
    "books.upload.hint2.description": "Service will create table of content based on chapters marked up on next step",
    "books.upload.hint3.image": "/assets/images/upload-no-decor.svg",
    "books.upload.hint3.title": "Remove decorations",
    "books.upload.hint3.description": "Service will add decorations, running titles based on selected template and styles",
    "books.upload.use-sample-doc": "Use sample doc",
    "books.upload.use-sample-doc-2": "Sample doc",
    "books.upload.use-empty-doc": "Create empty document",
    "books.upload.use-empty-doc-2": "Empty document",
    "books.upload.original-filename-hint": "Uploaded document name:",
    "books.upload.tables-in-multi-column-template-error.title": "Attention!",
    "books.upload.tables-in-multi-column-template-error.text": "You cannot use table files in the selected template. <br>Change the template or remove tables from the document",
    "books.upload.tables-in-multi-column-template-error.button": "Ok",
    "books.info-modal.header1": "New project",
    "books.info-modal.header1-edit": "Project information",
    "books.info-modal.header2": "Version",
    "books.info-modal.book-type-print": "printed",
    "books.info-modal.book-type-epub": "epub",
    "books.info-modal.enter-title-hint": "Please enter book title",
    "books.info-modal.enter-author-hint": "Please enter author name",
    "books.info-modal.at-least-one-book-type-expected-hint": "Please select at least one mode",
    "books.template.select-template-heading": "Select template",
    "books.template.format-custom": "Custom",
    "books.template.select-template-format-width": "Width (mm.)",
    "books.template.select-template-format-height": "Height (mm.)",
    "books.template.select-template.select-button": "Select",
    "books.template.select-template.details-button": "Preview",
    "books.template.select-template.empty-template-title": "Template from scratch",
    "books.template.select-template.empty-template-description": "Template from scratch",
    "books.template.select-template.tag.editable": "Editable",
    "books.template.select-template.tag.non-editable": "Non editable",
    "books.template.select-template.tag.blocked": "Blocked",
    "books.template.select-template.info.tables-in-document": "Document has tables",
    "books.template.select-template.warning-selected-non-editable-template": "Format settings are not available because a non-editable template is selected",
    "books.template.select-template.warning-on-select-non-editable-template": "If you select a non-editable template, you will not have access to margin settings, footer settings, font selection and style settings",
    "books.template.select-template.warning-reset-styles": "All styles missed in template will be replace by main text style",
    "books.template.select-template.template-not-suitable-for-uploaded-document": "The selected template is not suitable for the uploaded file. Please delete the tables or choose another template",
    "books.template.template-info.fonts": "Fonts:",
    "books.template.template-info.fonts-headings": "Headings",
    "books.template.template-info.fonts-main-text": "Main text",
    "books.template.template-info.font-size": "Font size:",
    "books.template.template-info.font-size-main-text": "Main text",
    "books.template.template-info.font-size-header1": "Heading «Section»",
    "books.template.template-info.font-size-header2": "Heading «Chapter»",
    "books.template.template-info.font-size-header3": "Heading «Subtitle»",
    "books.template.template-info.font-size-header4": "Heading «Subheading»",
    "books.template.template-info.font-size-units": "pt.",
    "books.template.template-info.margins": "Margins:",
    "books.template.template-info.margins-inner": "Inner",
    "books.template.template-info.margins-outer": "Ouside",
    "books.template.template-info.margins-top": "Top",
    "books.template.template-info.margins-bottom": "Bottom",
    "books.template.template-info.margins-units": "mm.",
    "books.template.template-info.running-titles": "Running titles:",
    "books.template.template-info.running-titles-position-top": "on top margin",
    "books.template.template-info.running-titles-position-bottom": "on bottom margin",
    "books.template.template-info.running-titles-position-outside": "on outside margin",
    "books.template.template-info.page-numbers": "Page numbers:",
    "books.template.template-info.page-numbers-position-top": "on top margin",
    "books.template.template-info.page-numbers-position-bottom": "on bottom margin",
    "books.template.template-info.page-numbers-position-outside": "on outside margin",
    "books.template.template-info.images-position": "Images position",
    "books.template.template-info.images-position-flex": "With text wrapping",
    "books.template.template-info.images-position-fixed": "Without text wrapping",
    "books.template.template-info.changes-info": "You can change template during next steps",
    "books.template.template-filter.all": "All",
    "books.template.template-filter.editable": "Editable",
    "books.template.template-filter.non-editable": "Non-editable",
    "books.markup.sidebar-tab-text": "Text",
    "books.markup.sidebar-tab-design": "Design",
    "books.markup.sidebar.fonts-title": "Fonts",
    "books.markup.sidebar.fonts-size-title": "Headers font size",
    "books.markup.sidebar.fonts-size-additional-sidebar": "Font size",
    "books.markup.sidebar.header-font": "Header font",
    "books.markup.sidebar.main-text-font": "Main text font",
    "books.markup.sidebar.text-styles": "Text styles",
    "books.markup.sidebar.colors-title": "Colors",
    "books.markup.sidebar.colors-styles-title": "Text color styles",
    "books.markup.sidebar.color-accent-title": "Accent color",
    "books.markup.sidebar.color-primary-title": "Primary color",
    "books.markup.sidebar.color-secondary-title": "Secondary color",
    "books.markup.sidebar.color-decor-title": "Color of decor",
    "books.markup.sidebar.images-title": "Image position",
    "books.markup.sidebar.images-fixed": "Fixed",
    "books.markup.sidebar.images-flex": "Flex",
    "books.markup.sidebar.images-fixed-description": "Тут текст фиксед,  надо править локализацию",
    "books.markup.sidebar.images-flex-description": "Тут текст флекс, надо править локализацию",
    "books.markup.sidebar.colors-description-display": "Choosing a display color can add extra contrast or emotion to the text, making it more attractive or expressive.",
    "books.markup.sidebar.colors-description-main": "The color of body text determines its readability, visual appeal, and helps convey information in accordance with the intended purposes of the document or design.",
    "books.markup.sidebar.colors-description-secondary": "The color of secondary text is typically used to highlight additional information or comments, emphasizing their importance or distinguishing them from the main content. It can also serve to create hierarchy in text or draw attention to certain details.",
    "books.markup.sidebar.images-description-fixed": "This mode saves image arrangement according to your text markup. Perfect for basic publications without conservative format. Without text wrapping.",
    "books.markup.sidebar.images-description-flex": "This mode will arrange the images in a variety of ways, based on the structure of the document. Meanwhile, images can change their text arrangement.",
    "books.markup.sidebar.images-description-flex-2": "With text wrapping",
    "books.markup.toolbar.add-text": "Text",
    "books.markup.toolbar.add-image": "Image",
    "books.markup.toolbar.add-list-unordered": "Marked",
    "books.markup.toolbar.add-list-ordered": "1. Numbered",
    "books.markup.toolbar.add-list-ordered-bkt": "1) Numbered",
    "books.markup.toolbar.add-list": "List",
    "books.markup.toolbar.add-table": "Table",
    "books.markup.toolbar.add-page-break": "Page break",
    "books.markup.toolbar.move-up": "Move up",
    "books.markup.toolbar.move-down": "Move down",
    "books.markup.toolbar.delete": "Delete",
    "books.markup.style-details.header": "Style settings",
    "books.markup.style-details.preview-text": "The quick brown fox jumps over the lazy dog",
    "books.markup.style-details.text settings-title": "Text settings",
    "books.markup.style-details.leading-title": "Line spacing",
    "books.markup.style-details.tracking-title": "Tracking size",
    "books.markup.style-details.decor-title": "Decoration",
    "books.markup.style-details.emphasis-title": "Emphasis",
    "books.markup.style-details.align-title": "Align",
    "books.markup.style-details.case-title": "Case",
    "books.markup.style-details.start-on-title": "Start on",
    "books.markup.style-details.one-on-page-title": "One on page",
    "books.markup.style-details.cancel": "Cancel",
    "books.markup.style-details.apply": "Apply",
    "books.markup.style-details.include-in-content": "Include in content",
    "books.markup.style-details.close": "Close",
    "books.markup.style-details.drop-cap-title": "Drop letter",
    "books.markup.style-details.drop-cap-description": "Drop cap only applies to the first paragraph of text that follows the styles",
    "books.markup.style-details.drop-cap-description-2": "and",
    "books.markup.style-details.font-size-tooltip": "Font size",
    "books.markup.style-details.leading-tooltip": "Line distance",
    "books.markup.style-details.tracking-tooltip": "Leter spacing",
    "books.markup.style-details.font-size-error": "The size of the text is too small, your manuscript will be readable",
    "books.markup.style-details.indent-paragraph-title": "Paragraph indent ",
    "books.markup.style-details.indent-paragraph-after-header-title": "Indented line after header",
    "books.markup.style-details.indent-paragraph-new-line-description": "Paragraph indent without paragraph break",
    "books.markup.style-details.indent-paragraph-indented-line-description": "Paragraph indent with a paragraph-break. It is not applied to paragraphs after headings. If you want to add the paragraph break - activate this option",
    "books.markup.style-details.indent-header-title": "Headers indent",
    "books.markup.add-toc-to-layout": "Add to layout",
    "books.markup.toc-position-start": "At the beginning",
    "books.markup.toc-position-end": "At the end",
    "books.markup.close-without-save-data": "Changes will not probably be saved.",
    "books.styles.main-text": "Main text",
    "books.styles.basic-information": "Basic information",
    "books.styles.h1": "Section",
    "books.styles.h2": "Chapter",
    "books.styles.h3": "Subtitle",
    "books.styles.h4": "Subheading",
    "books.styles.decorative-header": "Decorative header",
    "books.styles.lead": "Lead",
    "books.styles.quote": "Quote",
    "books.styles.quote-author": "The author of the quote",
    "books.styles.verse": "Verse",
    "books.styles.list": "List",
    "books.styles.note": "Note",
    "books.styles.caption": "Signature / Date",
    "books.styles.image": "Image",
    "books.styles.table": "Table",
    "books.styles.page-break": "Page break",
    "books.styles.epigraph": "Epigraph",
    "books.build.action-docx": "Shaping texts for the layout",
    "books.build.action-preview": "Making a layout, it can take up to 5 minutes",
    "books.build.action-final": "Creating a book",
    "books.build.open-style-editor-button": "Style editor",
    "books.build.produce-button": "Design a book",
    "books.build.hint-books-available": "{books, plural, zero {} one {One token available} few {{books} tokens available} many {{books} tokens  available} other{{books} tokens available}}",
    "books.build.hint-books-available-until": "until {$date}",
    "books.build.hint-books-available-watermark": "1 token will be spent",
    "books.build.hint-books-available-no-watermark": "2 tokens will be spent",
    "books.build.hint-hide-watermark": "Hide watermark (2 tokens)",
    "books.build.hint-preview-outdated": "Layout changes have been made. To see them, click \"Refresh layout\".",
    "books.build.hint-no-preview": "Preview images are not found. To create images, click \"Refresh layout\"",
    "books.build.update-preview-button": "Refresh layout",
    "books.build.build-sidebar-title": "Design",
    "books.build.build-sidebar-save-and-exit-button": "Save & Exit",
    "books.build.build-sidebar-section-format": "Format",
    "books.build.build-sidebar-section-layout": "Layout",
    "books.build.build-sidebar-section-genre": "Book genre",
    "books.build.build-sidebar-section-gen-type": "Space in the layout",
    "books.build.build-sidebar-section-gen-type-less": "less",
    "books.build.build-sidebar-section-gen-type-more": "more",
    "books.build.build-sidebar-section-header-size": "Header size",
    "books.build.build-sidebar-section-main-size": "Text size",
    "books.build.build-sidebar-section-main-size-error": "Text size should be in range from 6 to 20",
    "books.build.build-sidebar-section-header-size-less": "less",
    "books.build.build-sidebar-section-header-size-more": "more",
    "books.build.build-sidebar-section-fonts": "Fonts",
    "books.build.build-sidebar-section-colorscheme": "Color sheme",
    "books.build.build-sidebar-section-info": "About the book",
    "books.build.recommended-settings-not-found-master-pages-error": "masterPages not found",
    "books.build.recommended-settings-not-found-make-styles-error": "makeStyles not found",
    "books.build.cant-save-book-error": "Failed to save the book",
    "books.build.cant-start-processing-error": "Failed to start book creation",
    "books.build.cant-start-preview-error": "Failed to create preview files",
    "books.layout-settings.several-columns-label": "Multiple columns",
    "books.layout-settings.wide-margins-label": "Wide margins",
    "books.layout-settings.columns-disabled-hint": "Currently, only one column layout available for documents with tables",
    "books.script-settings.settings-not-found-error": "Settings not found",
    "books.styles.sidebar-toc-header": "Content",
    "books.styles.downloading-hint": "Downloading file",
    "books.styles.uploading-hint": "I upload the file to the server:",
    "books.styles.action-docx-hint": "Shaping texts for publishing",
    "books.styles.action-preview-hint": "I make a layout, it can take up to 5 minutes",
    "books.styles.action-final-hint": "Book creation",
    "books.styles.main-hint1": "Now we need to check that the text markup is correct. This is not the design stage, the fonts and colors are not the final ones. If your manuscript includes tables, text footnotes or table of contents, please remove them, they are not supported yet. This functionality will appear soon.",
    "books.styles.main-hint2": "The robot has detected the hierarchy of your text, but it may still make mistakes. Please make sure that the style markup of your manuscript is correct.",
    "books.styles.main-hint3": "If you want to make changes, click to a part of the text and select the appropriate style in the panel on the right. The table of contents on the left side of the screen helps you quickly navigate through the document.",
    "books.styles.next-button": "Next",
    "books.styles.style-delete-button": "Remove style",
    "books.styles.style-only-this-time-button": "Only in this case",
    "books.styles.style-action-button": "Action",
    "books.styles.cant-download-book-error": "Failed to upload document №{$book_id}",
    "books.styles.cant-process-book-error": "Failed to process document",
    "books.styles.cant-create-preview-error": "Failed to create preview files",
    "books.styles.cant-save-book-error": "Failed to save the document",
    "books.styles.need-remove-content-error": "Please revove content",
    "books.style-editor.h1-title": "First level header",
    "books.style-editor.h1-preview": "Header",
    "books.style-editor.h2-title": "Second level header",
    "books.style-editor.h2-preview": "Subheader",
    "books.style-editor.main-text-title": "Main text",
    "books.style-editor.main-text-preview": "At the beginning of July, in the very heat of the evening, a young man went out into the street, as if in indecision, from his lodgers in the C lane, and went slowly, as if in indecision, to the K-bridge. He safely avoided meeting his landlady on the stairs. His room was under the very roof of a tall five-storey building, and looked more like a closet than an apartment.\n",
    "books.style-editor.lead-title": "Insert",
    "books.style-editor.lead-preview": "The task of the lead is to hook the reader's attention, involving him in further reading\n",
    "books.style-editor.quote-title": "Quotes",
    "books.style-editor.quote-preview": "Whoever wants to – looks for a way, whoever does not want to – looks for a reason.",
    "books.style-editor.note-title": "Note",
    "books.style-editor.note-preview": "Additional note, explanation to the text.",
    "books.style-editor.random-styles-button": "Random styles",
    "books.style-editor.cancel-button": "Cancel",
    "books.style-editor.accept-button": "Save",
    "books.style-editor.accept-first-time-button": "Next",
    "books.style.text": "Text",
    "books.style.section": "Section",
    "books.style.chapter": "Chapter",
    "books.style.subtitle": "Subtitle",
    "books.style.subheading": "Subheading",
    "books.style.lead": "Lead",
    "books.style.annotation": "Comment",
    "books.style.quote": "Quote",
    "books.style.caption": "Caption/ date",
    "books.style.picture-caption": "Caption for the picture",
    "books.style.table-caption": "Table caption",
    "books.style.photo-caption": "Photo caption",
    "books.style.verse": "Verse",
    "books.markup.fix-errors": "We found errors in your markup, please fix it",
    "books.markup.empty-header": "Empty header",
    "books.markup.error.wrong-font-size": "Incorrect font size",
    "style.text.preview": "The quick brown fox jumps over the lazy dog",
    "books.margins.top": "Top margin",
    "books.margins.bottom": "Bottom margin",
    "books.margins.outer": "External margin",
    "books.margins.inner": "Radicular margin",
    "books.margins.gutter": "Middle margin",
    "books.margins.padding-wide": "Wide margin",
    "books.margins.hint": "Customize the margins",
    "books.margins.sidebar-tabs.printbook": "Print",
    "books.margins.sidebar-tabs.ebook": "Epub",
    "books.margins.maket": "Maket",
    "books.margins.columns-count": "Columns",
    "books.margins.paddings-wide": "Wide margins",
    "books.margins.space-level": "Air in maket",
    "books.margins.space-level-less": "less",
    "books.margins.space-level-more": "more",
    "books.margins.space-level-hint": "Margin width management based on golden ratio calculation",
    "books.margins.margins-size": "Margin sizes",
    "books.margins.sidebar-tabs.ebook.info": "Everything is ready for the ebook layout, you can make a layout right now!",
    "books.margins.sidebar-tabs.ebook.price": "Will ",
    "books.margins.warning-short-text-padding": "For use multy columns layout (add wide padding) you need to increase width text field",
    "books.margins.columns-count-info": "For use multy columns layout you need to increase width text field",
    "books.margins.wide-padding-info": "For add wide padding you need to increase width text field",
    "books.margins.columns-count-info-min-value": "Min. value:",
    "books.margins.columns-count-info-maket-value": "| In draft",
    "books.margins.action-save-margins-state": "Saving margins",
    "books.error.all-margins-small-for-running-titles": "Minimal margin size for vertical running-title -  20mm.\n",
    "books.error.some-margins-small-for-running-titles": "Text is too long",
    "books.error.empty-margin": "Margin must be filled",
    "books.error.should-be-digits": "Wrong value, numbers only",
    "books.error.should-be-gt-min-value": "Wrong value, must be not less than",
    "books.error.should-be-lt-max-value": "Wrong value, must be not greater than",
    "books.error.text-gt-margin-size": "Text is too long",
    "books.error.save-margins-state": "Error on save margins",
    "books.error.save-running-titles-state": "Error on save runnin titles",
    "books.nav-tabs.margins": "Margins",
    "books.nav-tabs.running-titles": "Running titles",
    "books.running-titles.hide-margins-borders": "Hide margins lines",
    "books.running-titles.show-margins-borders": "Show margins lines",
    "books.running-titles.running-title": "Running title",
    "books.running-titles.page-number": "Page number",
    "books.running-titles.running-titles-design": "Running titles design",
    "books.running-titles.page-number-design": "Page number design",
    "books.running-titles.info": "For margins is aviable positions os running titles:",
    "books.running-titles.running-title-left": "Left running title",
    "books.running-titles.running-title-right": "Right running title",
    "books.running-titles.options.author": "Book author",
    "books.running-titles.options.book-name": "Book name",
    "books.running-titles.options.chapters-name": "Chapter names",
    "books.running-titles.options.custom": "Another",
    "books.running-titles.options.empty": "Empty",
    "books.running-titles.chapter-name": "Chapter name",
    "books.running-titles.action-save-running-titles-state": "Saving running titles",
    "books.preview.process-info": "We have laid out the first pages of your book. If you want to make changes, use the corresponding item below.",
    "books.preview.process-info-2": "To get the entire book layout, click on the \"Make the whole book layout\" button.",
    "books.preview.upload-file": "Upload file",
    "books.preview.project-info": "Project info",
    "books.preview.templates": "Select maket",
    "books.preview.markup": "Markup",
    "books.preview.margins": "Margins",
    "books.preview.running-titles": "Runnin titles",
    "books.preview-page.edit.project-info": "Project data",
    "books.preview-page.edit.markup": "Markup",
    "books.preview-page.edit.margins": "Layout",
    "books.preview-page.edit.tilte": "Layout preview",
    "books.preview-page.edit.book-type.title": "Select version ",
    "books.preview-page.edit.export-print": "Print",
    "books.preview-page.edit.export-epub": "Epub",
    "books.preview.preview-info-text": "The eBook display may vary depending on the reading program or device you select.<br>\nDownload the preview using the button on the right.",
    "books.preview.download-epub": "Download EPUB",
    "books.preview.preview-epub": "EPUB Preview",
    "books.preview.layout-book": "Make a book",
    "books.preview.hide-watermark": "Hide watermark (2 t.)",
    "books.preview.payments-modal.header": "For layout book you need",
    "books.preview.payments-modal.your-balance": "You have:",
    "books.preview.payments-modal.use-gold": "Use",
    "books.preview.payments-modal.use-simple": "Approve",
    "books.preview.payments-modal.what-is-gold-credits": "What is it?",
    "books.preview.payments-modal.what-is-gold-credits-text": "With Supertoken you can layout entire book with any size",
    "books.preview.payments-modal.how-to-get-gold-credits": "How to get?",
    "books.preview.payments-modal.how-to-get-gold-credits-text": "Every two weeks we organize giveaways of 3 Supertokens. The main clause is the subscription to our social media <a class=\"link\" href=\"https://vk.com/editus\" target=\"_blank\">Vk</a>",
    "books.preview.payments-modal.vk": "Vk",
    "books.preview.payments-modal.and": "and",
    "books.preview.payments-modal.telegram": "Telegram",
    "books.preview.payments-modal.or": "or",
    "books.preview.payments-modal.not-enought": "Not enough",
    "books.preview.payments-modal.buy-credits": "Buy for",
    "books.preview.payments-modal.get-gold-credits": "How to get",
    "books.preview.payments-modal.not-enough-tokens": "Not enough tokens",
    "books.preview.payments-modal.header-01": "Cost of the layout:",
    "books.preview.payments-modal.header-02": "The current subscription has run out of tokens. Switch to a tariff with more tokens, or upgrade the current one.",
    "books.preview.payments-modal.use-gold-token-01": "Use supertoken",
    "books.error.select-type-before-finals": "Please select the type of book",
    "books.error.save-watermark-visible": "Error with watermark saving",
    "books.preview.processing-steps": "The process of layout of the first spreads of the book has begun.",
    "books.preview.processing-steps-2": "On this page you can keep track of your progress.",
    "books.layout-steps.step-start": "Document creation",
    "books.layout-steps.step-1": "Template rendering",
    "books.layout-steps.step-2": "Creating styles",
    "books.layout-steps.step-3": "Text verification",
    "books.layout-steps.step-4": "Headers processing",
    "books.layout-steps.step-stop": "Saving the result",
    "books.book.download-project-button": "Download project .zip",
    "books.margin.warning-multi-column-layout": "For multi-column layout, increase the width of the text box",
    "books.margin.warning-wide-paddings-layout": "For wide paddings layout, ",
    "books.margin.warning-wide-paddings-layout-2": "flex images position",
    "books.margin.warning-wide-paddings-layout-settings": "enable",
    "books.temp-ebook-not-work-yet": "For the layout of an e-book, all parameters related to fields and footers do not require additional configuration.",
    "books.markup.additional-settings-not-available-in-epub": "This settings is not applicable to epub and will be used for printed book only.",
    "books.images-position-settings-modal.title": "Images position",
    "books.free-tariff-notification.most-settings-is-not-available": "In the free plan you will not be able to<br>most of the settings",
    "books.free-tariff-notification.most-settings-is-not-available-column": "In the free plan, you<br>can't access most of the settings",
    "books.free-tariff-notification.trial-access-1": "Free",
    "books.free-tariff-notification.trial-access-2": "-daily access",
    "books.free-tariff-notification.trial-expire": "Expiring",
    "books.free-tariff-notification.get-tariff": "Unlock all the possibilities<br>by purchasing subscribing",
    "books.new.project-modal.complete": "Ready",
    "books.new.project-modal.general.section": "General information",
    "books.new.project-modal.general.title": "Title",
    "books.new.project-modal.general.title-placeholder": "Book title",
    "books.new.project-modal.general.author-last-name": "Author's last name",
    "books.new.project-modal.general.author-last-name-placeholder": "Author's last name",
    "books.new.project-modal.general.author-first-name": "Author's name",
    "books.new.project-modal.general.author-first-name-placeholder": "Author's name",
    "books.new.project-modal.general.author-middle-name": "Author's middle name",
    "books.new.project-modal.general.author-middle-name-placeholder": "Author's middle name",
    "books.new.project-modal.general.city": "City",
    "books.new.project-modal.general.city-placeholder": "City",
    "books.new.project-modal.general.year": "Year",
    "books.new.project-modal.general.year-placeholder": "Year",
    "books.new.project-modal.additional.section": "Select language and type",
    "books.new.project-modal.additional.language": "Language of publication",
    "books.new.project-modal.additional.age-limit": "Age restriction",
    "books.new.project-modal.additional.age-limits": "Age rating",
    "books.new.project-modal.additional.age-limits-0": "Content for kids under 6 years old may include symbolic scenes of violence if appropriate to the genre, taking into account sympathy for the victim and emphasising the victory of light forces over dark ones. ",
    "books.new.project-modal.additional.age-limits-6": "Descriptions of non-realistic scenes of illness, accidents and violence are acceptable, but without depicting their aftermath.",
    "books.new.project-modal.additional.age-limits-12": "Violence can be portrayed, but not in detail. Alcohol, tobacco and drugs - only in a judgemental context. Simple descriptions of hugs and kisses are allowed. ",
    "books.new.project-modal.additional.age-limits-16": "In books it is acceptable to mention diseases, disasters without details. Violence and the use of harmful substances are condemned. Coarse language yes, mate no. Sexual scenes without anatomical details. ",
    "books.new.project-modal.additional.age-limits-18": "The book contains detailed descriptions of illness, disasters, drug and alcohol use, realistic intimate scenes, unconventional relationships and profanity.",
    "books.new.project-modal.additional.project-version": "Version",
    "books.new.project-modal.additional.print-book": "Printed",
    "books.new.project-modal.additional.e-book": "Digital",
    "books.new.project-modal.additional.enable-edition-notice-page": "Include in layout",
    "books.new.project-modal.additional.edition-notice-page-enable-info": "The output page will be after the title page",
    "books.new.project-modal.additional.edition-notice-page-disable-info": "off",
    "books.new.project-modal.format.section": "Publication format",
    "books.new.project-modal.format.height": "Height, mm",
    "books.new.project-modal.format.width": "Width, mm",
    "books.new.project-modal.format.info": "If your publication contains tables, you may have difficulties with the layout. We recommend choosing a format that is at least 180 mm wide.",
    "books.new.project-modal.edition-notice.section": "Edition notice",
    "books.new.project-modal.edition-notice.isbn": "ISBN",
    "books.new.project-modal.edition-notice.udk": "UDK",
    "books.new.project-modal.edition-notice.bbk": "BBK",
    "books.new.project-modal.edition-notice.annotation": "Annotation",
    "books.new.project-modal.edition-notice.annotation-placeholder": "Text",
    "books.new.project-modal.edition-notice.adviser": "Supervisor",
    "books.new.project-modal.edition-notice.adviser-placeholder": "Adviser",
    "books.new.project-modal.edition-notice.reviewers": "Reviewers",
    "books.new.project-modal.edition-notice.reviewers-placeholder": "Reviewers",
    "book.language.ru": "Russian",
    "book.language.en": "English",
    "book.language.de": "Deutsch",
    "books.info-modal.projects-count-limit.title": "It's time to upgrade to the ",
    "books.info-modal.projects-count-limit.text": "On the",
    "books.info-modal.projects-count-limit.text-2": "plan you",
    "books.info-modal.projects-count-limit.text-3": "to edit. Upgrade your subscription to work on",
    "books.info-modal.projects-count-limit.text-4": "at a time.",
    "books.info-modal.projects-count-limit.unlimited-projects-count": "unlimited projects count",
    "books.info-modal.projects-count-limit.pluralize-available": "{value, plural, zero {have} one {have} two {have} few {have} many {have} other {have} }",
    "books.info-modal.projects-count-limit.pluralize-projects-count": "{value, plural, zero {projects} one {project} two {projects} few {projects} many {projects} other {projects} }",
    "books.info-modal.projects-count-limit.pluralize-available-projects-count": "{value, plural, zero {projects} one {project} two {projects} few {projects} many {projects} other {projects} }",
    "books.info-modal.projects-count-limit.action-text": "Select a tariff",
    "books.info-modal.file-size-limit.title": "File size is larger than allowed",
    "books.info-modal.file-size-limit.text": "On FREE tariff the maximum size of the downloaded file is {$fileLimit} mb. Unblock downloading of large files by switching to BEGINNER tariff.",
    "books.info-modal.file-size-limit.action-text": "Select a tariff",
    "books.layout-as-a-gift-conditions.text": "To do this, the following conditions must be met:<br> – The length of the manuscript is no more than 80 thousand characters<br> – There are no tables",
    "books.layout-as-a-gift-conditions": "To do this, the following conditions must be met:",
    "books.info-modal.free-layout.title!": "Layout for free!",
    "books.info-modal.free-layout.title": "Layout for free",
    "books.info-modal.free-layout.text": "Your book is less than 80 000 characters. This means that we will make it for you free of charge.",
    "books.info-modal.free-layout.action-text": "Thank you.",
    "books.epub.layout.error.epub-fixer-service-error": "Error processing epub file",
    "books.format.unit": "mm",
    "books.template.create.cover": "Create cover art",
    "books.template.create.cover.description": "Generate with AI or upload your own image",
    "books.modal.header.upload-step": "Upload document",
    "books.modal.header.book-data-step": "Questionnaire",
    "books.modal.upload-template.title": "Upload template",
    "books.modal.upload-template.phone": "Phone number",
    "books.modal.upload-template.phone.placeholder": "Phone number",
    "books.modal.upload-template.email": "Email",
    "books.modal.upload-template.email.placeholder": "Email",
    "books.modal.upload-template.upload-button.text-1": "Drag and drop here",
    "books.modal.upload-template.upload-button.text-2": "or",
    "books.modal.upload-template.upload-button.text-2-upload": "upload",
    "books.modal.upload-template.upload-button.text-3": "file",
    "books.modal.upload-template.request.button": "Send request",
    "books.modal.upload-template.request.success": "The file has been uploaded. A manager will contact you shortly",
    "books.modal.upload-template.request.error": "An error occurred while uploading the file",
    "books.modal.upload-template.request.error.file-limit": "File size is too large",
    "books.modal.upload-template.upload.process-hint": "File uploading",
    "books.modal.upload-template.upload.description": "If you are creating multiple editions with a similar layout, you can upload an example design or indd file. <br><br>We will adapt it for use in the editor, which will significantly speed up the layout process of your future projects.",
    "books.breadcrumb.book-data": "Project questionnaire",
    "books.breadcrumb.preview": "Preview and layout",
    "cover.modal.create.title": "New cover",
    "cover.modal.create.project-name.placeholder": "Book title",
    "cover.modal.create.author-firstname.placeholder": "First Name",
    "cover.modal.create.author-lastname.placeholder": "Surname",
    "cover.modal.create.select-format": "Select format",
    "cover.modal.create.custom-format": "Custom size",
    "cover.modal.create.enter-size": "Enter size",
    "cover.modal.create.enter-size.width": "Width",
    "cover.modal.create.enter-size.height": "Height",
    "cover.modal.create.create-button": "Create",
    "books.preview.update-preview.modal.title": "Run a preview?",
    "books.preview.update-preview.modal.yes": "Yes",
    "books.preview.update-preview.modal.no": "No",
    "pricing.currency": "₽",
    "pricing.breadcrumbs.tariffs-and-payments": "Rates and payments",
    "pricing.breadcrumbs.payments": "Payments",
    "pricing.nav-tabs.subscription": "Subscription",
    "pricing.nav-tabs.buying-credits": "Buying tokens",
    "pricing.nav-tabs.payment-history": "Payment history",
    "pricing.header": "Rates",
    "pricing.books-available": "Tokens available",
    "pricing.item1.title": "1 token (book)",
    "pricing.item1.comment": "1 token = 1 layout of unlimited number of pages",
    "pricing.item1.price": "€ 7,5 ",
    "pricing.item1.old-price": "€15",
    "pricing.item1.url": "",
    "pricing.item2.title": "10 tokens (books)",
    "pricing.item2.comment": "1 token = 1 layout of unlimited number of pages",
    "pricing.item2.price": "€ 70,5",
    "pricing.item2.old-price": "€141",
    "pricing.item2.url": "",
    "pricing.item3.title": "30 tokens (books)",
    "pricing.item3.comment": "1 token = 1 layout of unlimited number of pages",
    "pricing.item3.price": "€ 161,5 ",
    "pricing.item3.old-price": "€323",
    "pricing.item3.url": "",
    "pricing.buy-button": "Buy",
    "pricing.not-available-title": "Currently, purchase is not available",
    "pricing.not-available-desc": "coming soon",
    "pricing.email-note": "Use your email for payment ",
    "pricing.calculator.heading": "Calculate your book price",
    "pricing.calculator.text-count": "Symbols count, thousand symbols",
    "pricing.calculator.images-count": "Images count",
    "pricing.calculator.tables-count": "Tables count",
    "pricing.calculator.credits-count": "Tokens count",
    "pricing.calculator.for-free": "Free",
    "pricing.calculator.calculate": "Calculate",
    "pricing.calculator.recalculate": "Recalculate",
    "pricing.calculator.result.heading": "Benefit from automatic layout",
    "pricing.calculator.result.market-average": "Market average",
    "pricing.calculator.result.price": "Price",
    "pricing.calculator.result.currency": "$",
    "pricing.calculator.result.time": "Time",
    "pricing.calculator.result.service-average": "In Metranpage",
    "pricing.calculator.result.time-unit-hours": "h.",
    "pricing.calculator.result.time-unit-minutes": "min.",
    "pricing.calculator.result.pay": "Pay",
    "pricing.calculator.panel.calculate-book": "Let`s calculate how many tokens you need to layout your book?",
    "pricing.calculator.panel.new-calculate-book": "Calculate another book?",
    "pricing.calculator.panel.for-layout-need": "For the layout of the book you need",
    "pricing.calculator.panel.pluralize-for-layout-need": "{credits, plural, zero {For the layout of your book you need} one {For the layout of your book you need} two {For the layout of your book you need} few {For the layout of your book you need} many {For the layout of your book you need} other {For the layout of your book you need} }",
    "pricing.calculator.panel.this-profit-1": "It is cheaper than the average market price for",
    "pricing.calculator.panel.this-profit-2": "and faster at",
    "pricing.calculator.panel.show-calculation": "Show calculation",
    "pricing.calculator.panel.buy-in-addition": "buy for",
    "pricing.calculator.panel.layout": "To layout",
    "pricing.calculator.panel.not-enough": "Not enough",
    "pricing.calculator.panel.use-gold-credit": "or use",
    "pricing.calculator.panel.gold-credit": "Supertoken",
    "pricing.news.header": "We change our policy price!",
    "pricing.news.header.date": "March 1st, 2023",
    "pricing.news.body-1": "Number ",
    "pricing.news.body-2": "for lauout will depend on the number of printed characters, images and tables ",
    "pricing.news.body.link": "tokens",
    "pricing.news.popup.heading-1": "All tokens",
    "pricing.news.popup.heading-2": "bought before March 1st, 2023",
    "pricing.news.popup.heading-3": "will be kept and become gold!",
    "pricing.news.popup.block-1-1": "With one Supertoken",
    "pricing.news.popup.block-1-2": "you will be able to make a layout of the book with unlimited number of pages",
    "pricing.news.popup.block-2": "From March 1st ",
    "pricing.news.popup.block-3-1": "The price",
    "pricing.news.popup.block-3-2": "of the token is 450 rubles",
    "pricing.news.popup.block-4-1": "The new token will enable to make the text layout ",
    "pricing.news.popup.block-4-2": "of 40,000 printed characters",
    "pricing.news.popup.block-4-3": "for any format and style",
    "pricing.news.popup.block-4-4": "Images",
    "pricing.news.popup.block-4-5": "and",
    "pricing.news.popup.block-4-6": "tables",
    "pricing.news.popup.block-4-7": "are recounted to the characters using the fomula",
    "pricing.news.popup.block-5-1": "2000 characters",
    "pricing.news.popup.block-5-2": "1000 characters",
    "pricing.news.popup.block-6-1": "40,000 printed characters",
    "pricing.news.popup.block-6-2": "40,000 printed characters ",
    "pricing.message.header": "Save the current tokens and make layouts without limitations for the best price!",
    "pricing.message.body-1": "Current tokens enable to make a book layout ",
    "pricing.message.body-2": "of any content and number of pages",
    "pricing.message.offer-until": "Only till",
    "pricing.message.offer-date": "March, the 1st",
    "pricing.error.empty-field": "The field must be filled",
    "pricing.error.should-be-digits": "Wrong value, digits only",
    "pricing.error.should-be-gt-zero": "Wrong value, value must be above zero",
    "pricing.error.should-be-eq-gt-zero": "Wrong value, value must be positive",
    "pricing.credit-table.author-list": "a.l.,",
    "pricing.credit-table.to-profit": "Profit",
    "pricing.credit-table.pay": "Pay",
    "pricing.credit-table.any-credits-count": "Any tokens count",
    "pricing.subscription-table.month": "month",
    "pricing.subscription-table.year": "year",
    "pricing.promocode-panel.enter-promocode": "Enter promocode",
    "pricing.promocode-panel.activate-promocode": "Activate",
    "pricing.pluralize-credits": "{credits, plural, zero {tokens} one {token} two {tokens} few {tokens} many {tokens} other {tokens} }",
    "pricing.pluralize-books": "{books, plural, zero {book} one {book} two {books} few {books} many {books} other {books} }",
    "pricing.pluralize-author-lists": "{value, plural, zero {author lists} one {author list} two {author lists} few {author lists} many {author lists} other {author lists} }",
    "pricing.pluralize-symbols": "{symbols, plural, zero {symbol} one {symbol} two {symbols} few {symbols} many {symbols} other {symbols} }",
    "pricing.pluralize-simple-credits": "{credits, plural, zero {common} one {common} two {common} few {common} many {common} other {common} }",
    "pricing.pluralize-gold-credits": "{credits, plural, zero {supertokens} one {supertoken} two {supertokens} few {supertokens} many {supertokens} other {supertokens} }",
    "pricing.pluralize-not-enought-credits": "{credits, plural, zero {tokens} one {token} two {tokens} few {tokens} many {tokens} other {tokens} }",
    "pricing.pluralize-days": "{days, plural, zero {days} one {day} two {days} few {days} many {days} other {days} }",
    "pricing.pluralize-weeks": "{weeks, plural, zero {weeks} one {week} two {weeks} few {weeks} many {weeks} other {weeks} }",
    "pricing.pluralize-months": "{months, plural, zero {months} one {month} two {months} few {months} many {months} other {months} }",
    "pricing.pluralize-years": "{years, plural, zero {years} one {year} two {years} few {years} many {years} other {years} }",
    "pricing.pluralize-active-projects": "{value, plural, zero {active projects} one {active project} two {active projects} few {active projects} many {active projects} other {active projects} }",
    "pricing.info-panel.header": "Available:",
    "pricing.info-panel.and": "and",
    "pricing.info-panel.sub-header-1": "and",
    "pricing.info-panel.sub-header-2": "any volume.",
    "pricing.info-panel.more-info": "More info about tokens",
    "pricing.info-panel.body-1": "1 token",
    "pricing.info-panel.body-2": " = layout",
    "pricing.info-panel.body-3": "1 author list (a.l.) = 40 000 printed characters,",
    "pricing.info-panel.body-4": "images and tables calculated to characters as:",
    "pricing.info-panel.images-to-symbols": " = 8 000 characters",
    "pricing.info-panel.tables-to-symbols": " = 12 000 characters",
    "pricing.info-panel.modal.header-1": "Price",
    "pricing.info-panel.modal.header-2": "token – ",
    "pricing.info-panel.modal.body": "1 token allow layout text volume <br><b>40 k. symbols*</b> any format and any formalization. <br><br><b>Images</b> and <b>tables</b> calculate to symbols as:",
    "pricing.info-panel.modal.footer": "<b>*40 k. characters</b> - volume 1 author list, ≈25 pages. А5, or ≈13 pages. А4, a set of 12 font size.",
    "pricing.info-panel.modal.body-1": "1 token allow layout text volume",
    "pricing.info-panel.modal.body-2": "40 k. characters*",
    "pricing.info-panel.modal.body-3": "any format and any style",
    "pricing.info-panel.modal.body-4": "Images",
    "pricing.info-panel.modal.body-5": "and",
    "pricing.info-panel.modal.body-6": "tables",
    "pricing.info-panel.modal.body-7": "calculate to characters as:",
    "pricing.info-panel.modal.body-8": "*40,000 characters",
    "pricing.info-panel.modal.body-9": "- volume 1 author list, ≈25 pages. А5, or ≈13 pages. А4, a set of 12 font size.",
    "pricing.tariff-table.buy-credits": "Tokens",
    "pricing.tariff-table.subscription": "Subscription",
    "pricing.tariff-table.footnote-1": "* As the period passes, unused tokens expire. When paying for a month, the selected tariff will be extended",
    "pricing.tariff-table.footnote-2": "automatically within one year. You can cancel automatic payments at any time before the due date.",
    "pricing.tariff-table.footnote-3": "** Purchased tokens remain until you spend them",
    "pricing.tariff-table.thousand": "thousand ",
    "pricing.tariff-table.million": "million ",
    "payment.errors.cant-get-payment-url": "Cannot generate payment link",
    "payment.errors.payment-url-blocked": "Cannot open payment link. Checkout browser notifications and allow opening popups from this site.",
    "payment.errors.insufficient-funds": "Insufficient number of tokens",
    "pricing.pluralize-charge-off": "{credits, plural, zero {charge off} one {charge off} two {charge off} few {charge off} many {charge off} other {charge off} }",
    "pricing.credit-short": "token",
    "pricing.tariffs.title": "Subscription to the service",
    "pricing.tariffs.for-whom.start": "For beginners",
    "pricing.tariffs.for-whom.pro": "For active users",
    "pricing.tariffs.for-whom.business": "For teams ",
    "pricing.tariffs.for-whom.free": "For beginners",
    "pricing.tariffs.for-whom.beginner": "For common authors",
    "pricing.tariffs.for-whom.specialist": "For active authors",
    "pricing.tariffs.for-whom.master": "For business",
    "pricing.pluralize-symbols.1": "{symbols, plural, zero {symbol} one {symbol} two {symbols} few {symbols} many {symbols} other {symbols} }",
    "pricing.pluralize-images.1": "{images, plural, zero {image} one {image} two {images} few {images} many {images} other {images} }",
    "pricing.tariffs.generation": "Generation of",
    "pricing.tariffs.features.ulimited-upload-size": "Unlimited upload file size",
    "pricing.tariffs.features.template-editing": "Layout template editing",
    "pricing.tariffs.features.layout-images": "Using images in layouts",
    "pricing.tariffs.features.layout-tables": "Using tables in layouts",
    "pricing.tariffs.features.no-watermark": "No watermarks",
    "pricing.tariffs.features.autopublish-auto": "Automatic publishing in community section",
    "pricing.tariffs.features.autopublish-manual": "Manual publishing in community section",
    "pricing.tariffs.features.output-pdf": "Output print-ready file (.pdf)",
    "pricing.tariffs.features.output-src-indd": "Output source file (.indd)",
    "pricing.tariffs.features.output-epub": "Output digital book (.ePub)",
    "pricing.tariffs.features.2": "Layout of a publication of up to",
    "pricing.tariffs.features.2-1": "or",
    "pricing.tariffs.features.2-2": "Include",
    "pricing.tariffs.features.2-3": "tokens/month",
    "pricing.tariffs.features.2-4": "This is the layout of publications with a total volume of up to",
    "pricing.tariffs.features.3.free": "Text and image layout ",
    "pricing.tariffs.features.3": "Text, image and table layout ",
    "pricing.tariffs.features.4": "Unlimited number of projects ",
    "pricing.tariffs.features.5.free": "Drafts without changing option ",
    "pricing.tariffs.features.5": "Any drafts with changing option",
    "pricing.tariffs.features.6": "File for editing available for printing (.pdf, InDesign)",
    "pricing.tariffs.features.7": "Maximum file size ",
    "pricing.tariffs.features.7.units": "MB",
    "pricing.tariffs.features.7.business": "Without limits ",
    "pricing.tariffs.features.8-1": "Generating ",
    "pricing.tariffs.features.8-2": "covers for working project with changing option ",
    "pricing.tariffs.features.8-2.free": "covers for working project without changing option ",
    "pricing.tariffs.features.9": "Price 1 token – ",
    "pricing.tariffs.start.features.1": "Suitable for users who want learn the technology.",
    "pricing.tariffs.start.features.1-1": "trial period with full functionality of the service",
    "pricing.tariffs.pro.features.1": "Perfect for authors, designers and people who regularly work with books ",
    "pricing.tariffs.business.features.1": "Working tool for optimizing your company's workflow",
    "pricing.tariffs.gift-time": "for free",
    "pricing.tariffs.period.month": "Month",
    "pricing.tariffs.period.half-year": "Half a year",
    "pricing.tariffs.period.year": "Year",
    "pricing.tariffs.economy": "Economy",
    "pricing.tariffs.tariff-card.buttons.start": "Start",
    "pricing.tariffs.tariff-card.buttons.connect": "Start",
    "pricing.tariffs.tariff-card.buttons.bill": "Start",
    "pricing.tariffs.tariff-card.buttons.upgrade": "Upgrade",
    "pricing.tariffs.tariff-card.buttons.downgrade": "Downgrade",
    "pricing.tariffs.tariff-card.buttons.refresh": "Top up",
    "pricing.tariffs.tariff-card.buttons.active": "Active",
    "pricing.tariffs.tariff-card.more-about": "More about the tariff",
    "pricing.tariffs.tariff-active-card.tariff-title": "Your current service plan: ",
    "pricing.tariffs.tariff-active-card.expire": "Expires",
    "pricing.tariffs.tariff-active-card.settings-button": "Change the service plan ",
    "pricing.tariffs.accrual-period.tokens-one-time": "Tokens are credited on a one-time basis",
    "pricing.tariffs.accrual-period.payment-once-a-month": "Payment once a month",
    "pricing.tariffs.accrual-period.payment-once-a-half-year": "Semi-annual payment",
    "pricing.tariffs.accrual-period.payment-once-a-year": "Payment once a year",
    "pricing.success-page.title": "Gratitude!",
    "pricing.success-page.text": "Payment success",
    "pricing.success-page.return": "Back",
    "pricing.failed-page.title": "Payment failed",
    "pricing.failed-page.text": "Try repeat payment later",
    "pricing.failed-page.return": "Back",
    "pricing.next-tariff-connected-page.title-1": "Tariff",
    "pricing.next-tariff-connected-page.title-2": "activated",
    "pricing.next-tariff-connected-page.text": "Switch to tariff will be automatic after paid period end",
    "pricing.next-tariff-connected-page.return": "Back",
    "pricing.promocode.title": "Get your present from Editus",
    "pricing.promocode.description": "Subscription to service or automatic layout of any book with your exceptional design using your individual promo code",
    "pricing.promocode.enter-promocode": "Add promo code ",
    "pricing.promocode.activate-button": "Activate",
    "pricing.promocode.activation.success": "Promo code activated",
    "pricing.promocode.activation.confirm": "That's great.",
    "pricing.promocode.activation.error.already-used": "Promocode is already used",
    "pricing.promocode.activation.error.expired": "Promocode is expired",
    "pricing.promocode.activation.error.not-found": "Promocode not correct",
    "pricing.promocode.activation.error.error-occurs": "Error occurs, please repeat later",
    "pricing.promocode.activation.error.error-active-subscription-has-higher-level": "Entered promo code worsens your current subscription.",
    "pricing.promocode.discount.title": "Activated",
    "pricing.promocode.discount.title-accent": "discount",
    "pricing.promocode.discount.description": "For one payment on any tariff up to",
    "pricing.faq.title-1": "Why do I need subscription?",
    "pricing.faq.text-1": "The subscription unlocks all the features of the service, including the ability to modify templates, fields, headers and all text styles. In addition, the subscription allows for uploading files of any size, formatting publications with tables, having more than one active project, and obtaining tokens at a lower cost.",
    "pricing.faq.title-2": "Do the unused tokens expire?",
    "pricing.faq.text-2": "No. When you sign up for a monthly subscription, the number of tokens in your personal cabinet will be updated each month to equal the token limit of your subscription. For example, if you have SPECIALIST tariff and the number of tokens in your personal cabinet by the end of the month will be equal to 100 tokens, then at the moment of automatic subscription renewal the number of tokens will be equal to the sum of SPECIALIST tariff 3500 tokens and the balance of 100 tokens - 3600 tokens. <br>When you switch to a higher tariff, all current tokens are added to the new tariff and are valid for exactly the tariff plan period you choose.",
    "pricing.faq.title-3": "How can I get a discount for tokens?",
    "pricing.faq.text-3": "The more expensive the tariff you buy, the cheaper the tokens are for you.<br>In the SPECIALIST tariff, the token is 6% cheaper<br>In the MASTER tariff, the token is 17% cheaper<br>than in the BEGINNER tariff.",
    "pricing.faq.title-4": "How can I cancel the subscription?",
    "pricing.faq.text-4": "Switch to a free plan. The transition will happen automatically at the end of the paid period.",
    "pricing.faq.title-5": "What should I do if there are no tokens on my account?",
    "pricing.faq.text-5": "You can update your current tariff. In this case, the balance of tokens will be updated to the maximum limit of your subscription and will be valid for the entire month from the moment of activation.<br>Or you can upgrade to a higher tariff.",
    "pricing.max-count-symbols-in-tariff-1.1": "Up to",
    "pricing.max-count-symbols-in-tariff-1.2": "symbols",
    "pricing.max-count-images-in-tariff-1.1": "Generation",
    "pricing.max-count-images-in-tariff-1.2": "images",
    "pricing.balance": "You have:",
    "premium-tariffs-modal.title": "Discover all the possibilities",
    "premium-tariffs-modal.premium.title": "Premium",
    "premium-tariffs-modal.description": "Subscribe to unlock premium functionality. And the ability to create multiple projects.",
    "editor.placeholder": "Start typing...",
    "editor.plugins.placeholder.heading_0": "Enter heading 0",
    "editor.plugins.placeholder.heading_1": "Enter heading 1",
    "editor.plugins.placeholder.heading_2": "Enter heading 2",
    "editor.plugins.placeholder.heading_3": "Enter heading 3",
    "editor.plugins.placeholder.heading_4": "Enter heading 4",
    "editor.plugins.placeholder.lead": "Enter the lead",
    "editor.plugins.placeholder.annotation": "Enter a comment ",
    "editor.plugins.placeholder.photo_sign": "Enter photo sign",
    "editor.plugins.placeholder.quote": "Enter quote",
    "editor.plugins.placeholder.quote_author": "Enter sign/date",
    "editor.plugins.placeholder.verse": "Enter verse",
    "editor.plugins.placeholder.footnotes": "Enter footnote",
    "editor.plugins.heading_0": "Heading 0",
    "editor.plugins.heading_1": "Heading 1",
    "editor.plugins.heading_2": "Heading 2",
    "editor.plugins.heading_3": "Heading 3",
    "editor.plugins.heading_4": "Heading 4",
    "editor.plugins.lead": "Lead",
    "editor.plugins.annotation": "Annotation",
    "editor.plugins.photo_sign": "Photo sign",
    "editor.plugins.quote": "Quote",
    "editor.plugins.quote_author": "Sign/date",
    "editor.plugins.verse": "Verse",
    "editor.plugins.image": "Image",
    "editor.image.upload_hint_p1": "Drag and drop here or",
    "editor.image.upload_hint_p2": "upload",
    "editor.image.upload_hint_p3": "image file",
    "editor.image.upload_error": "Cannot upload image file",
    "editor.image.not_an_image_error": "Uploaded file is not an image",
    "editor.image.file-extension-error": "A file of this type is not suitable",
    "editor.errors.toc": "Please remove table of contents",
    "editor.errors.table": "Please remove the table",
    "editor.errors.table-cell-length": "we have found the boxes with a large number of symbols. Please make the text in table boxes shorter than 1000 characters.",
    "editor.errors.action_delete": "delete",
    "editor.table.context_menu.add": "paste",
    "editor.table.context_menu.add_rows_above": "{rows, plural, zero {} one {Add {rows} row above} other {Add {rows} rows above} }",
    "editor.table.context_menu.add_rows_below": "{rows, plural, zero {} one {Add {rows} row below} other {Add {rows} rows below} }",
    "editor.table.context_menu.add_columns_right": "{cols, plural, zero {} one {Add {cols} column right} other {Add {cols} columns right} }",
    "editor.table.context_menu.add_columns_left": "{cols, plural, zero {} one {Add {cols} column left} other {Add {cols} columns left} }",
    "editor.table.context_menu.delete": "Delete",
    "editor.table.context_menu.delete_rows": "{rows, plural, zero {} one {Delete {rows} row} other {Delete {rows} rows} }",
    "editor.table.context_menu.delete_columns": "{cols, plural, zero {} one {Delete {cols} сolumn} other {Delete {cols} сolumns} }",
    "editor.table.context_menu.delete_table": "table",
    "editor.table.context_menu.union_cells": "Merge the boxes",
    "editor.table.toolbar.add_row": "Add row below",
    "editor.table.toolbar.add_column": "Add column to right",
    "editor.image.select_size": "size at layout",
    "editor.image.size_small": "small",
    "editor.image.size_normal": "normal",
    "editor.image.size_large": "large",
    "editor.image.select_crop": "crop",
    "editor.select.list_style": "select list style",
    "editor.image.size_fullpage": "full page",
    "editor.image.allow_crop": "image cropping",
    "editor.image.allow_crop_hint": "We will crop image when needed. Original image will still be available at exported Indesign file (.indd), which you may change ",
    "editor.starts-on.next-page": "Start from new page",
    "editor.starts-on.odd-page": "Start from odd page",
    "editor.starts-on.even-page": "Start from even page",
    "editor.block.warning.posible-incorrect-union-blocks": "Posible incorrect union blocks",
    "editor.block.warning.check-list": "Check list",
    "editor.block.warning.check-symbols": "Check symbols",
    "editor.block.warning.single-symbol": "Single symbol",
    "editor.block.warning.undefined-symbols": "Undefined symbols",
    "editor.block.warning.starts-with-sup": "Starts with sup",
    "editor.block.warning.starts-with-sub": "Starts with sub",
    "editor.block.warning.dublicate-text": "Dublicate text",
    "editor.block.warning.no-spaces": "No spaces",
    "editor.block.warning.many-spaces": "Many spaces",
    "editor.block.warning.containes-private-char": "Containes private char",
    "editor.context-menu.ai.image-generation": "Generate image",
    "editor.context-menu.ai.upgrade-text": "Enhance text",
    "editor.context-menu.ai.check-grammar": "Check grammar",
    "editor.context-menu.ai.increase-text": "Lengthen text",
    "editor.context-menu.ai.decrease-text": "Shorten text",
    "editor.context-menu.ai.tone": "Change text tone",
    "editor.context-menu.ai.tone.professional": "Professional",
    "editor.context-menu.ai.tone.normal": "Normal",
    "editor.context-menu.ai.tone.straightforward": "Straightforward",
    "editor.context-menu.ai.tone.confident": "Confident",
    "editor.context-menu.ai.tone.friendly": "Friendly",
    "editor.context-menu.ai.what-do-you-want-placeholder": "Write what you want to do with the text you receive",
    "editor.context-menu.ai.apply-to-selection": "Apply to selection",
    "editor.context-menu.ai.paste-below": "Paste below",
    "editor.context-menu.ai.continue-generating": "Continue generating",
    "editor.context-menu.ai.lengthen-current": "Lengthen current",
    "editor.context-menu.ai.shorten-current": "Shorten the text",
    "editor.context-menu.ai.regenerate": "Regenerate",
    "editor.context-menu.ai.cancel-1": "Cancel",
    "editor.context-menu.ai.delete-generation-confirm": "Are you sure you want to delete the current generation?",
    "editor.context-menu.ai.delete-generation": "Delete generation",
    "editor.context-menu.ai.cancel-2": "Cancel",
    "admin.nav.users": "Users",
    "admin.nav.feature-flags": "Feature Flags",
    "admin.nav.public-templates": "Public templates",
    "admin.nav.templates": "Templates",
    "admin.nav.non-editable-templates": "Non editable templates",
    "admin.nav.company": "Company",
    "admin.nav.companies": "Companies",
    "admin.nav.tariffs": "Subscription plans",
    "admin.nav.promocodes": "Promo codes",
    "admin.nav.referal-links": "Referals links",
    "admin.nav.fonts": "Fonts",
    "admin.nav.api": "API",
    "admin.nav.s3": "S3 Settings",
    "admin.nav.payments": "Payments settings",
    "admin.nav.print-settings": "Print settings",
    "admin.nav.unisender": "Unisender",
    "admin.nav.image-generation-price": "Image generation price",
    "admin.nav.image-generation-watermark": "Customizing watermark on generated images",
    "admin.nav.edition-notice-settings": "Edition notice ",
    "admin.nav.moderation.images": "Images moderation",
    "admin.nav.moderation.covers": "Covers moderation",
    "admin.nav.telegram-settings": "Settings Telegram",
    "admin.nav.formats": "Formats",
    "admin.nav.rewards-onetime": "One-time rewards",
    "admin.nav.rewards-daily": "Daily rewards",
    "admin.nav.social-network-covers": "Обложки социальных сетей",
    "admin.nav.maintenance-settings": "Maintenance",
    "admin.nav.cover-snippets": "Cover Snippets",
    "admin.common.create": "Create",
    "admin.common.delete": "Delete",
    "admin.common.save": "Save",
    "admin.common.hide": "Hide",
    "admin.common.cancel": "Cancel",
    "admin.common.select.all": "All",
    "admin.common.copy-text": "Copy text",
    "admin.common.details": "Details",
    "admin.common.edit": "Edit",
    "admin.templates.add-template": "Add template",
    "admin.templates.template.title": "Title",
    "admin.templates.template.description-short": "Descritption (short)",
    "admin.templates.template.description-long": "Description",
    "admin.templates.template.description-long-tags-description": "<b>TEXT</b> - bold, <i>TEXT</i> - italic",
    "admin.templates.template.description-long-preview": "Preview",
    "admin.templates.template.available-to-user": "Available to users",
    "admin.templates.template.available-to-companies": "Available to companies",
    "admin.templates.template.is-premium": "Premium template",
    "admin.templates.template.section-sizes": "Dimensions",
    "admin.templates.template.width-from": "Width from (mm.)",
    "admin.templates.template.width-to": "Width to (mm.)",
    "admin.templates.template.height-from": "Height from (mm.)",
    "admin.templates.template.height-to": "Height to (mm.)",
    "admin.templates.template.error.cant-add-template": "Cannot add template",
    "admin.templates.template.error.cant-delete-template": "Cannot delete template",
    "admin.templates.template.error.cant-update-template": "Cannot update template",
    "admin.templates.template.error.cant-copy-template": "Cannot copy template",
    "admin.templates.template.error.cant-upload-preview": "Cannot upload preview",
    "admin.templates.template.error.cant-update-order": "Cannot update order",
    "admin.templates.template.hide-template-confirmation": "Hide template?",
    "admin.templates.template.section-styles": "Styles",
    "admin.templates.template.font-main": "Main text font",
    "admin.templates.template.font-heading": "Heading font",
    "admin.templates.template.main-text-size": "Main text size",
    "admin.templates.template.section-colors": "Colors",
    "admin.templates.template.color-accent": "Accent",
    "admin.templates.template.color-primary": "Primary",
    "admin.templates.template.color-secondary": "Secondary",
    "admin.templates.template.color-decor": "Decor",
    "admin.templates.template.section-layout": "Layout",
    "admin.templates.template.center-images": "Center images",
    "admin.templates.template.margin-top": "Top",
    "admin.templates.template.margin-bottom": "Bottom",
    "admin.templates.template.margin-inner": "Inner",
    "admin.templates.template.margin-outer": "Outer",
    "admin.templates.template.gutter": "Gutter",
    "admin.templates.template.columns-count": "Columns count",
    "admin.templates.template.wide-padding": "Wide pad",
    "admin.templates.template.wide-padding-left-pos": "Left page",
    "admin.templates.template.wide-padding-right-pos": "Right page",
    "admin.templates.template.wide-padding-size": "Padding size",
    "admin.templates.template.section-running-title": "Running Title",
    "admin.templates.template.rt-state": "State",
    "admin.templates.template.rt-left": "Left page",
    "admin.templates.template.rt-right": "Right page",
    "admin.templates.template.rt-position": "Position",
    "admin.templates.template.rt-style": "Style",
    "admin.templates.template.pn-state": "Page number",
    "admin.templates.template.pn-position": "Position",
    "admin.templates.template.pn-style": "Style",
    "admin.template.add-style": "Add style",
    "admin.templates.template.styles.localization": "Localization",
    "admin.templates.template.styles-title": "Text styles",
    "admin.templates.template.styles.font": "Font name",
    "admin.templates.template.styles.font-size": "Font size",
    "admin.templates.template.styles.leading": "Leading",
    "admin.templates.template.styles.tracking": "Tracking",
    "admin.templates.template.styles.emphasis": "Emphasis",
    "admin.templates.template.styles.align-maintext": "Align main text",
    "admin.templates.template.styles.align": "Align",
    "admin.templates.template.styles.case": "Case",
    "admin.templates.template.styles.one-on-page": "One on page",
    "admin.templates.template.styles.start-on": "Start on",
    "admin.templates.template.styles.drop-cap": "Drop cap",
    "admin.templates.template.styles.drop-cap-chars": "Drop cap chars number",
    "admin.templates.template.styles.drop-cap-lines": "Drop cap lines number",
    "admin.templates.template.styles.indent-paragraph": "Indent paragraph",
    "admin.templates.template.styles.indent-paragraph-after-header": "Indented line after headers",
    "admin.templates.template.styles.indent-header": "Indent header",
    "admin.templates.template.styles.type": "Style type",
    "admin.templates.template.toc": "Table of content",
    "admin.templates.template.toc-visible": "Add table of content",
    "admin.templates.template.toc-position": "Table of content position",
    "admin.templates.template.company": "Company",
    "admin.templates.template.editable-settings": "Edit settings",
    "admin.templates.template.is-editable": "Is editable",
    "admin.templates.template.non-editable-template-name": "Name non editable template",
    "admin.templates.template.images-settings-available": "Images settings available",
    "admin.templates.template.images-size": "Images size",
    "admin.templates.template.images-crop-class": "Images crop class",
    "admin.templates.template.styles.is-displayed-at-sidebar": "Is displayed at sidebar ",
    "admin.templates.template.styles.is-configurable-at-details-sidebar": "Is configurable at details sidebar",
    "admin.non-editable-templates.non-editable-template.add-non-editable-template": "Add template",
    "admin.non-editable-templates.non-editable-template.download-non-editable-template-example": "Download example for template",
    "admin.non-editable-templates.non-editable-template.delete-non-editable-template": "Delete",
    "admin.non-editable-templates.non-editable-template.error.cant-add-non-editable-template": "Cannot add non editable template",
    "admin.non-editable-templates.non-editable-template.error.cant-delete-non-editable-template": "Cannot delete non editable  template",
    "admin.non-editable-templates.non-editable-template.error.cant-update-non-editable-template": "Cannot update non editable template",
    "admin.non-editable-templates.non-editable-template.error.cant-copy-non-editable-template": "Cannot copy non editable template",
    "admin.non-editable-templates.non-editable-template.error.cant-upload-archive": "Cannot upload archive",
    "admin.non-editable-templates.non-editable-template.error.title-must-be-unique": "Template title must be unique",
    "admin.non-editable-templates.non-editable-template.error.template-file-not-found": "Template file not found",
    "admin.non-editable-templates.non-editable-template.error.settings-file-not-found": "Template settings file settings.json not found",
    "admin.companies.add-company": "Add company",
    "admin.companies.edit-company": "Edit company",
    "admin.companies.change-company-data": "Change company data?",
    "admin.companies.company.title": "Title",
    "admin.companies.company.landing-url": "Landing URL",
    "admin.companies.company.host-url": "Site URL",
    "admin.companies.company.knowledge-base-url": "Knowledge base URL",
    "admin.companies.company.receipt-template-for-subscriptions": "Template for receipts (subscription)",
    "admin.companies.company.unisender-templates": "Unisender email templates",
    "admin.companies.company.unisender-go-templates": "Unisender Go email templates",
    "admin.companies.company.unisender-template-language.ru": "Russian",
    "admin.companies.company.unisender-template-language.en": "English",
    "admin.companies.company.unisender-go-template-id.verification-email": "Verification Email",
    "admin.companies.company.unisender-go-template-id.restore-password": "RestorePassword",
    "admin.companies.company.unisender-go-template-id.subscribtion-ending-notice": "Subscribtion Ending Notice",
    "admin.companies.company.unisender-go-template-id.fail-payment-notice": "Fail Payment Notice",
    "admin.companies.company.unisender-go-template-id.force-free-tariff-notice": "Force Free Tariff Notice",
    "admin.companies.company.unisender-go-template-id.trial-end-notice": "Trial End Notice",
    "admin.companies.company.unisender-template-settings.title": "Title",
    "admin.companies.company.unisender-template-settings.list-id": "List Id",
    "admin.companies.company.unisender-template-settings.timeout": "Timeout",
    "admin.companies.company.can-produce-epub": "ePub layout",
    "admin.companies.company.is-logout-button-available": "Display the «Logout» button",
    "admin.companies.company.welcome-credits-count": "Number of welcome tokens",
    "admin.companies.company.error.cant-create-company": "Company create error",
    "admin.companies.company.error.cant-delete-company": "Company deletion error",
    "admin.companies.company.delete-company-confirmation": "Delete company",
    "admin.tariffs.add-tariff": "Add tariff",
    "admin.tariffs.tariff.title": "Title",
    "admin.tariffs.tariff.subtitle": "For whom",
    "admin.tariffs.tariff.company-title": "Company",
    "admin.tariffs.tariff.price": "Price",
    "admin.tariffs.tariff.profit": "Benefit",
    "admin.tariffs.tariff.currency": "Currency",
    "admin.tariffs.tariff.period": "Period",
    "admin.tariffs.tariff.credits-per-month": "Number of tokens a month",
    "admin.tariffs.tariff.gold-credits-per-month": "Number of Supertokens a month ",
    "admin.tariffs.tariff.credits-helper": "Will be added upon activation of the subscription plan:",
    "admin.tariffs.tariff.active-projects-count": "Number of active projects ",
    "admin.tariffs.tariff.is-free": "Free service plan ",
    "admin.tariffs.tariff.gift-period": "Months for free",
    "admin.tariffs.tariff.trial-period": "Probation period ",
    "admin.tariffs.tariff.legal-entity": "For legal entities",
    "admin.tariffs.tariff.file-volume-limit": "File limit",
    "admin.tariffs.tariff.level": "Level",
    "admin.tariffs.tariff.credit-price": "Credit price with subscription",
    "admin.tariffs.tariff.is-unlimited": "Is unlimited",
    "admin.tariffs.tariff.error.cant-update-tariff": "Tariff update error",
    "admin.tariffs.tariff.error.cant-create-tariff": "Tariff create error",
    "admin.tariffs.tariff.error.cant-delete-tariff": "Tariff deletion error",
    "admin.tariffs.tariff.delete-tariff-confirmation": "Delete tariff",
    "admin.tariffs.tariff.error.cant-copy-tariff": "Tariff copy error",
    "admin.companies.company.image-generation-price.basic": "Basic generation",
    "admin.companies.company.image-generation-price.advanced": "Advanced generation",
    "admin.companies.company.image-generation-price.advanced.fast": "Advanced generation (fast mode)",
    "admin.companies.company.image-generation-price.advanced.quality": "Advanced generation (qualitative mode)",
    "admin.companies.company.image-generation-price.advanced.ultra": "Advanced generation (ultra mode)",
    "admin.companies.company.image-generation-price.advanced.fabula": "Advanced generation Face Swap (Fabula AI)",
    "admin.companies.company.image-generation-price.creative": "Creative generation",
    "admin.companies.company.image-generation-price.other": "Other",
    "admin.companies.company.image-generation-price.1": "1 Image generation price (1 images generation), tokens",
    "admin.companies.company.image-generation-price.2": "1 Image generation price (2 images generation), tokens",
    "admin.companies.company.image-generation-price.3": "1 Image generation price (3 images generation), tokens",
    "admin.companies.company.image-generation-price.4": "1 Image generation price (4 images generation), tokens",
    "admin.companies.company.image-generation-price.unzoom": "1 Image unzoom price, tokens",
    "admin.companies.company.image-generation-price.upscale": "1 Image upscaleprice, tokens",
    "admin.companies.company.image-generation-price.nobg": "1 Image delete background, tokens",
    "admin.companies.company.image-generation-price.coefficient-for-generation-with-elements": "Coefficient for generating 1 image with an element",
    "admin.companies.company.image-generation-price.coefficient-for-convert-leonardo-tokens": "Coefficient converting Leonardo tokens",
    "admin.companies.company.image-generation-price.fabula-remove-background": "Cost of background removal (Fabula), tokens",
    "admin.companies.company.image-generation-price.fabula-character-reference-coefficient": "Character reference, coefficient",
    "admin.companies.company.image-generation-price.creative-character-reference-coefficient": "Character reference, coefficient",
    "admin.companies.company.image-generation-watermark.title": "Customize watermark on generated images",
    "admin.companies.company.image-generation-watermark.company-logo-image": "Company logo",
    "admin.companies.company.image-generation-watermark.is-visible": "Watermark visible",
    "admin.companies.company.image-generation-watermark.offset-bottom": "Offset from bottom of lower right corner",
    "admin.companies.company.image-generation-watermark.offset-right": "Offset to the right of the lower right corner",
    "admin.companies.company.image-generation-watermark-settings.no-image-file.error": "No image file",
    "admin.companies.company.image-generation-watermark-settings.upload-button.text-1": "Drag and drop here",
    "admin.companies.company.image-generation-watermark-settings.upload-button.text-2": "or",
    "admin.companies.company.image-generation-watermark-settings.upload-button.text-2-upload": "upload",
    "admin.companies.company.image-generation-watermark-settings.upload-button.text-3": "file",
    "admin.companies.company.image-generation-watermark-settings.upload-another-button.text-1-upload": "Upload",
    "admin.companies.company.image-generation-watermark-settings.upload-another-button.text-2": "another file",
    "admin.currencies.ruouble": "Ruble",
    "admin.currencies.dollar-us": "Dollar",
    "admin.currencies.euro": "Euro",
    "admin.currencies.pound-sterling": "Pound Sterling",
    "admin.promocodes.add-promocode": "Add promocode",
    "admin.promocodes.promocode.delete-promocode-confirmation": "Delete promocode",
    "admin.promocodes.promocode.error.cant-create-promocode": "Error create promocode",
    "admin.promocodes.promocode.error.cant-delete-promocode": "Error delete promocode",
    "admin.promocodes.promocode.token": "Token",
    "admin.promocodes.promocode.status": "Status",
    "admin.promocodes.promocode.company": "Company",
    "admin.promocodes.promocode.agent": "Agent",
    "admin.promocodes.promocode.created-date": "Start date",
    "admin.promocodes.promocode.expire-date": "Expire date",
    "admin.promocodes.promocode.single-use-or-multiple": "Single use or multiple",
    "admin.promocodes.promocode.single-use": "Single use (for one user only)",
    "admin.promocodes.promocode.description": "Description ",
    "admin.promocodes.promocode.credits-count": "Tokens count",
    "admin.promocodes.promocode.gold-credits-count": "Supertokens count",
    "admin.promocodes.promocode.tariff-id": "subscription ID",
    "admin.promocodes.promocode.subscription-period": "Subscription period",
    "admin.promocodes.promocode.discont-for-subscription": "Discont for subscription, %",
    "admin.promocodes.promocode.error.cant-update-promocode": "Error update promocode",
    "admin.promocodes.promocode.select-company": "Select company",
    "admin.promocodes.promocode.select-tariff": "Select tariff",
    "admin.promocodes.promocode.error.promocode-token-must-be-unique": "Promocode token must be unique",
    "admin.promocodes.promocode-type.tokens": "Tokens ",
    "admin.promocodes.promocode-type.discount": "Discount",
    "admin.promocodes.promocode-type.subscription": "Subscription",
    "admin.referal-links.add-referal-link": "Add referal link",
    "admin.referal-links.referal-link.delete-referal-link-confirmation": "Delete referal link",
    "admin.referal-links.referal-link.error.cant-create-referal-link": "Error create referal link",
    "admin.referal-links.referal-link.error.cant-delete-referal-link": "Error delete referal link",
    "admin.referal-links.referal-link.token": "Token",
    "admin.referal-links.referal-link.company": "Company",
    "admin.referal-links.referal-link.agent-fee": "Agency fee",
    "admin.referal-links.referal-link.error.cant-update-referal-link": "Error update referal link",
    "admin.referal-links.referal-link.error.referal-link-token-must-be-unique": "Referal link token must be unique",
    "admin.users.add-subscription.error.active-tariff-is-higher": "Active tariff is higher",
    "admin.users.add-subscription.error.active-subscription-has-next-tariff": "Active subscription has next tariff",
    "admin.fonts.add-font": "Add font",
    "admin.fonts.font.delete-font-confirmation": "Delete font",
    "admin.fonts.font.error.cant-create-font": "Error create font",
    "admin.fonts.font.error.cant-delete-font": "Error delete font",
    "admin.fonts.font.name": "Name",
    "admin.fonts.font.family-name": "Font family",
    "admin.fonts.font.subfamily-name": "Subfamily",
    "admin.fonts.font.file": "Font file",
    "admin.fonts.font.license": "License",
    "admin.fonts.font.license-placeholder": "License",
    "admin.fonts.font.is-available-for-headers": "Available for headers",
    "admin.fonts.font.is-available-for-main-text": "Available for main text",
    "admin.fonts.font.is-selectable": "Is selectable",
    "admin.fonts.font.error.cant-update-font": "Error update font",
    "admin.fonts.font.error.font-name-must-be-unique": "Font name must be unique",
    "admin.fonts.upload-help": "<br>Fonts can be downloaded one file at a time and the license text can be specified\n<br>You can download a .zip archive with the fonts, and a license.json file in the root of the archive, which specifies the license text for each locale \n<br>{\n<br>ru: \"license text in Russian\",\n<br>en: \"license text in English\"\n<br>}\n<br>If there is no license.json file, the license value will be applied from the font file \n\nTranslated with DeepL.com (free version)",
    "admin.api": "API",
    "admin.api.available-token": "API token",
    "admin.api.reset-token": "Reset token",
    "admin.api.link-to-doc": "More info:",
    "admin.api.webhook-url": "Webhook URL",
    "admin.api.test-webhook": "Send test request",
    "admin.api.webhook-result": "Request result",
    "admin.api.redirect-on-layout-finish-url": "Redirect URL after the layout is finished",
    "admin.company.currency": "Currency",
    "admin.cloudpayments.enable": "Payment via CloudPayments",
    "admin.cloudpayments.public-id": "PublicId",
    "admin.cloudpayments.private-key": "PrivateKey",
    "admin.cloudpayments.description": "You can use CloudPayments account to process users payments. More info on <a href=\"https://developers.cloudpayments.ru/#obschaya-informatsiya\">Cloud Payments</a> documentation",
    "admin.stripe.enable": "Payment via Stripe",
    "admin.stripe.public-key": "PublicKey",
    "admin.stripe.secret-key": "SecretKey",
    "admin.stripe.webhook-secret": "WebhookSecret",
    "admin.stripe.description": "You can use Stripe account to process users payments. More info on <a href=\"https://docs.stripe.com/sdks\">Stripe</a> documentation",
    "admin.s3.endpoint": "Endpoint",
    "admin.s3.region": "Region",
    "admin.s3.bucket": "Bucket",
    "admin.s3.access-key": "AccessKey",
    "admin.s3.secret-key": "SecretKey",
    "admin.unisender.unisender-api-key": "Unisender Api Key",
    "admin.unisender.unisender-go-api-key": "Unisender Go Api Key",
    "admin.print-settings.print-settings-style": "Print settings style",
    "admin.print-settings.print-settings-style-calc": "Print calc",
    "admin.print-settings.print-settings-style-redirect-button": "Redirect button",
    "admin.print-settings.print-settings-style-hidden": "Hidden",
    "admin.print-settings.redirect-url": "Redirect url",
    "admin.print-settings.redirect-url-description": "You can specify a link where the user will be redirected to when clicking the \"Go to Print\" button. The format of the link is \"http://yourdomain/books/[id]/preview\". Available values: id, integrationProjectId, isbn, width, height",
    "superadmin.auth.auth-error": "Cannot authorize",
    "superadmin.companies-settings.common": "General",
    "superadmin.companies-settings.admins": "Admins",
    "superadmin.companies-settings.add-admin": "Add admin",
    "superadmin.companies-settings.add-admin-email": "Email",
    "superadmin.companies-settings.account": "Accounts",
    "admin.companies.company.edition-notice-settings.template": "Output data page template",
    "admin.companies.company.edition-notice-settings.template-small": "Small Output Page Template",
    "admin.companies.company.edition-notice-settings.preview": "Output page preview",
    "admin.companies.company.edition-notice-settings-not-found": "Edition notice settings not found",
    "admin.companies.company.edition-notice-empty-fields": "Add files to each language",
    "admin.companies.company.edition-notice.legel-name": "Legal name of the company",
    "admin.companies.company.edition-notice.project-name": "Company name",
    "admin.companies.company.edition-notice.project-description": "Company description",
    "admin.moderation.images.queue.no-images": "No images on moderation",
    "admin.moderation.images.generation-data.prompt": "Prompt",
    "admin.moderation.images.accept": "Add to community",
    "admin.moderation.images.decline": "Decline",
    "admin.moderation.images.description-placeholder": "Enter a reason",
    "admin.moderation.images.description-options.0": "Other:",
    "admin.moderation.images.description-options.1": "Copyright infringement",
    "admin.moderation.images.description-options.2": "Inappropriate content",
    "admin.moderation.images.description-options.3": "No reason given",
    "admin.moderation.images.filter-options.user": "From user",
    "admin.moderation.images.filter-options.free-tariff": "From free tariff",
    "admin.moderation.images.error.update": "Error when updating image data",
    "admin.moderation.covers.queue.no-covers": "No covers on moderation",
    "admin.moderation.covers.generation-data.prompt": "Prompt",
    "admin.moderation.covers.accept": "Add to community",
    "admin.moderation.covers.decline": "Decline",
    "admin.moderation.covers.description-placeholder": "Enter a reason",
    "admin.moderation.covers.description-options.0": "Other:",
    "admin.moderation.covers.description-options.1": "Copyright infringement",
    "admin.moderation.covers.description-options.2": "Inappropriate content",
    "admin.moderation.covers.filter-options.user": "From user",
    "admin.moderation.covers.filter-options.free-tariff": "From free tariff",
    "admin.moderation.covers.error.update": "Error when updating cover data",
    "admin.account-main.buttons.title": "Admin buttons",
    "admin.account-main.buttons.notification-test.button": "Notification test",
    "admin.account-main.buttons.notification-test.image-moderation-success.option": "Successful image moderation",
    "admin.account-main.buttons.notification-test.image-moderation-fail.option": "Unsuccessful image moderation",
    "admin.account-main.buttons.notification-test.cover-moderation-success.option": "Successful cover moderation",
    "admin.account-main.buttons.notification-test.cover-moderation-fail.option": "Unsuccessful cover moderation",
    "admin.companies.company.telegram-settings.tilte": "Telegram connection settings",
    "admin.companies.company.telegram-settings.can-connect": "Telegram connectivity",
    "admin.companies.company.telegram-settings.channel-id": "Telegram channel ID",
    "admin.companies.company.telegram-settings.channel-url": "Telegram channel URL",
    "admin.companies.company.telegram-settings.bot-url": "Telegram bot URL",
    "admin.companies.company.maintenance-settings.tilte": "Maintenance settings",
    "admin.companies.company.maintenance-settings.image-generation.basic": "Basic generation mode",
    "admin.companies.company.maintenance-settings.image-generation.advanced": "Advanced generation mode",
    "admin.companies.company.maintenance-settings.action-enable-description": "Maintenance mode enabled",
    "admin.companies.company.maintenance-settings.action-disable-description": "Maintenance mode disabled",
    "admin.format": "Formats",
    "admin.format.add-format": "Add a format",
    "admin.format.add-format.title": "Name",
    "admin.format.add-format.value": "Designation",
    "admin.format.add-format.type": "Type",
    "admin.format.add-format.width": "Width",
    "admin.format.add-format.height": "Height",
    "admin.format.add-format.is-available-to-user": "Available to users",
    "admin.format.add-format.is-available-to-other-companies": "Available to companies",
    "admin.format.hide-format-confirmation": "Hide format?",
    "admin.format.buttons.add-format": "New format",
    "admin.format.error.cant-add-format": "Failed to add format",
    "admin.format.error.cant-delete-format": "Failed to delete a format",
    "admin.format.error.cant-update-format": "Failed to update format",
    "admin.format.delete-format-confirmation": "Delete format",
    "admin.rewards.onetime.add": "Add a one-time reward",
    "admin.rewards.onetime.delete-confirmation": "Delete one-time reward?",
    "admin.rewards.onetime.error.cant-create": "Failed to add one-time reward",
    "admin.rewards.onetime.error.cant-delete": "Failed to delete one-time reward",
    "admin.rewards.onetime.error.cant-update": "Failed to update the one-time reward",
    "admin.rewards.onetime.type": "Task type",
    "admin.rewards.onetime.limit": "Number of actions",
    "admin.rewards.onetime.reward-credits-count": "Reward (tokens)",
    "admin.rewards.daily.add": "Add a daily reward",
    "admin.rewards.daily.delete-confirmation": "Delete daily  reward?",
    "admin.rewards.daily.error.cant-create": "Failed to add daily reward",
    "admin.rewards.daily.error.cant-delete": "Could not delete daily reward",
    "admin.rewards.daily.error.cant-update": "Failed to update the daily reward",
    "admin.rewards.daily.type": "Task type",
    "admin.rewards.daily.limit": "Number of actions",
    "admin.rewards.daily.reward-credits-count": "Reward (tokens)",
    "admin.rewards.daily.task.likes.take": "Likes received",
    "admin.rewards.daily.task.likes.give": "Likes",
    "admin.rewards.onetime.task.subscribe-to-telegram-channel": "Subscribe to Telegram channel",
    "onboarding.buttons.next": "Next",
    "onboarding.buttons.back": "Back",
    "onboarding.buttons.complete": "Complete",
    "onboarding.buttons.cancel": "Cancel",
    "onboarding.buttons.close": "Close",
    "onboarding.title": "Onboarding",
    "onboarding.upload-page.title": "Uploading document",
    "onboarding.upload-page.text-step-1": "Please prepare your manuscript in .docx format or create a blank document",
    "onboarding.templates-page.title": "Templates",
    "onboarding.templates-page.text-step-1": "Please point out the desirable format of your book, there are no design limitations ",
    "onboarding.templates-page.text-step-2": "Please select one of the given templates or start creating a new project from scratch ",
    "onboarding.markup-page.title": "Markup",
    "onboarding.markup-page.text-step-1": "To make a global change in text format, use this slider. The size of the main text remains unchanged.",
    "onboarding.markup-page.text-step-2": "To make a selective change, please click on the gear sign opposite to the style needed.",
    "onboarding.markup-page.text-step-3": "Apart from the text size, you may set up the paragraph style, i.e. typeface, alignment, register and placement. The placement change is available for the text styles \"Heading 1\" and \"Heading 2\" only.",
    "onboarding.markup-page.text-step-4": "With this setting, the heading starts from a new page.",
    "onboarding.markup-page.text-step-5": "And with this setting the page starts from an odd page (the right side of the page spread) ",
    "onboarding.markup-page.text-step-6": "On the \"Design\" tab page you may change project colors and choose the image placement ",
    "onboarding.markup-page.text-step-7": "You may select one of the palette colors",
    "onboarding.markup-page.text-step-8": "And alter it to your taste by ckicking on the gear sign",
    "onboarding.markup-page.text-step-9": "To include the table of content into the layout, please switch the slider and point out its placement. It will be automatically generated according to your book`s headings ",
    "onboarding.markup-page.text-step-10": "To add new blocks or page breaks, use this command button, it will appear immediately after clicking on any layout block ",
    "onboarding.markup-page.text-step-11": "To change or delete the block position, please click on this command button and choose the action needed ",
    "onboarding.margins-page.title": "Margins",
    "onboarding.margins-page.text-step-1": "Manage your draft, add the columnar format, additional wide margin and regulate the external margin size\r\n",
    "onboarding.running-titles-page.title": "Running titles",
    "onboarding.running-titles-page.text-step-1": "Set up the running titles and page numbers, choose their position and content ",
    "onboarding.preview-page.title": "Preview",
    "onboarding.preview-page.text-step-1": "This screen demonstrates the first pages of your book. If everything is correct, click on the \"Make up a book\" command button. The layout process of the whole book will be started",
    "onboarding.books-page.title": "Projects",
    "onboarding.books-page.text-step-1": "Here you can change the book title, author's name, year and city of publication of your edition",
    "onboarding.books-page.text-step-2": "After completing the book layout, clicking on this button you will be able to download an archive with all formats: .pdf for printing and previewing, working project assembly in InDesign.",
    "onboarding.books-page.text-step-3": "Clicking on this button will take you to project editing, where you can change the questionnaire data and get to the markup of the book",
    "onboarding.books-page.text-step-4": "Project duplication. ",
    "onboarding.books-page.text-step-5": "Deleting a project. Be careful, it is impossible to stop deletion or return a deleted project.",
    "onboarding.home-page.title": "Comunity Generations",
    "onboarding.home-page.text-step-1": "Welcome to \"Community Generations\", here you can see illustrations generated by other users. \nThese images have passed our moderation, which means they are worthy of appearing on the main page of the service.",
    "onboarding.home-page.text-step-2": "In the description of each illustration, you can see the parameters that have been set by the users.",
    "onboarding.home-page.text-step-3": "If you like the illustration, you can use these settings in your work",
    "onboarding.home-page.text-step-4": "Here you can see your projects that you've been working on ",
    "onboarding.home-page.text-step-5": "This is where you can start working on your illustrations",
    "onboarding.image-generation-page.title": "Image generation",
    "onboarding.image-generation-basic.title": "Basic settings",
    "onboarding.image-generation-advanced.title": "Advanced settings",
    "onboarding.image-generation-creative.title": "Сreative settings",
    "onboarding.image-generation-page.text-step-1": "The service offers two types of settings:\n\"Basic\" — simple options for beginners to quickly achieve results without in-depth involvement",
    "onboarding.image-generation-page.text-step-2": "\"Advanced\" — includes the basic settings but with added professional detailing for generation",
    "onboarding.image-generation-page.text-step-3": "Select the proportions and orientation of the images. ",
    "onboarding.image-generation-page.text-step-4": "Specify the number of image variants. Keep in mind that this affects the generation cost",
    "onboarding.image-generation-page.text-step-5": "Specify the main idea or request for the output. This can be a description of a key scene, a character's image, or an abstract phrase. Anything you want to see on the cover or illustration",
    "onboarding.image-generation-page.text-step-6": "Choose from a variety of styles. If you are creating illustrations for a book, we recommend using one style to maintain a consistent visual character",
    "onboarding.image-generation-page.text-step-7": "Specify the level of image detail. The slider to the left indicates minimalism, while to the right indicates high detail",
    "onboarding.image-generation-page.text-step-8": "Specify the mood of the image",
    "onboarding.image-generation-page.text-step-9": "Fast — ideal for finding ideas and drafts with fewer tokens. Better — takes more time but produces more detailed results. Ultra — maximizes the size and quality level of images.",
    "onboarding.image-generation-page.text-step-10": "Specify the color scheme. For example, only black and white or blue and red tones. You can set these parameters for the neural network here",
    "onboarding.image-generation-page.text-step-11": "In this field you can describe what should not be in the main plot and remove unnecessary elements. Here it is good to work with ready-made variants of images to get the ideal final variant of the cover",
    "onboarding.image-generation-page.text-step-12": "Experiment with model selection. The generation result depends on the chosen model. The service recommends a model based on technique and realism level",
    "onboarding.image-generation-page.text-step-13": "Experiment with generation modes. Choosing a different mode will yield new results",
    "onboarding.image-generation-page.text-step-14": "The number of tokens used depends on the number of illustration variants.",
    "onboarding.image-generation-page.text-step-15": "Add Characters. This function is designed to preserve the appearance of characters from generation to generation",
    "onboarding.image-generation-page.text-step-16": "The “Face Repeat” function generates images with the selected face. Use only human images - animals, robots and transformers are not suitable.",
    "onboarding.modal-book-templates.title": "Select project type",
    "onboarding.modal-book-templates.text-step-1": "Create a cover for your project",
    "onboarding.cover-page.title": "Cover editor",
    "onboarding.cover-page.text-step-1": "Enable the visible working area of the cover. We recommend keeping text and important elements within this area",
    "onboarding.cover-page.text-step-2": "Add necessary elements to the layout: text, images, and shapes",
    "onboarding.cover-page.text-step-3": "Change the positions of the layers. The higher the layer in the list, the higher it appears in the working area",
    "onboarding.cover-page.text-step-4": "By clicking on the desired layer, you can drag it, change its angle, and adjust parameters such as font, color, size, and text alignment",
    "onboarding.cover-page.text-step-5": "Here you can select or change the font, text color, size, and set a fixed width and height for the text. You can also adjust the alignment: center, left, or right",
    "onboarding.cover-page.text-step-6": "When the cover is bright and colorful, text can blend with the image. To avoid this, you can add a background shape behind the text. In the working area, you can resize the shape and assign border colors",
    "onboarding.cover-page.text-step-7": "Any element (text, image, shape) can be locked or deleted in the layers. Locking prevents accidental movement of elements. This is done using icons in the working area",
    "cover.breadcrumb.create-step": "Create cover",
    "cover.breadcrumb.generation-step": "Cover generation",
    "cover.breadcrumb.crop-step": "Crop",
    "cover.breadcrumb.preview-step": "Preview",
    "image-generation.results.results-will-be-shown-here": "Generated images will be here",
    "image-generation.results.no-results.enter-prompt": "Enter prompt and press",
    "image-generation.results.no-results.press-generate": "Generate",
    "image-generation.image.preview-button": "Preview",
    "image-generation.image.select-button": "Select",
    "image-generation.image.publish-image-button": "Publish",
    "image-generation.sidebar.tabs.basic": "Basic",
    "image-generation.sidebar.tabs.advanced": "Advanced",
    "image-generation.sidebar.tabs.creative": "Creative",
    "image-generation.style-details.apply": "Apply",
    "image-generation.style-details.selected-style": "",
    "image-generation.image-size": "Party ratio",
    "image-generation.image-size.width": "Width",
    "image-generation.image-size.height": "Height",
    "image-generation.image-count": "Images count",
    "image-generation.advanced-generation-mode": "Quality level",
    "image-generation.prompt": "Prompt",
    "image-generation.prompt.placeholder": "Prompt",
    "image-generation.select-style": "Select style",
    "image-generation.show-all-styles": "all",
    "image-generation.generate": "Generate",
    "image-generation.styles-restriction.advanced.error-text-1": "Select only human images for Face Repetition — animals, robots, and transformers will not work. Try",
    "image-generation.styles-restriction.advanced.error-action": "creative mode",
    "image-generation.styles-restriction.advanced.error-text-2": "generation mode for alternative results!",
    "image-generation.styles-restriction.creative.error-text-1": "For the Face Repeat function, choose only human images — animals, robots and Transformers are not suitable. Try",
    "image-generation.styles-restriction.creative.error-action": "basic mode",
    "image-generation.styles-restriction.creative.error-text-2": "for alternative results!",
    "image-generation.selected-mode": "Mode",
    "image-generation.selected-mode.basic": "Basic",
    "image-generation.selected-mode.advanced": "Advanced",
    "image-generation.selected-mode.creative": "Creative",
    "image-generation.selected-mode.face-swap": "Face swap",
    "image-generation.selected-style": "Style",
    "image-generation.selected-mood": "Mood",
    "image-generation.selected-detalization": "Detalization",
    "image-generation.selected-quality-level": "Quality level",
    "image-generation.select-style.group.graphics": "Graphics",
    "image-generation.select-style.group.painting": "Painting",
    "image-generation.select-style.group.digital": "Digital",
    "image-generation.select-style.group.photo": "Photo",
    "image-generation.select-style.group.children-drawing": "Children's",
    "image-generation.select-style.group.fantastic": "Fiction",
    "image-generation.select-style.group.comics": "Comics",
    "image-generation.select-style.group.all": "All",
    "image-generation.select-style.group.premium": "Premium",
    "image-generation.select-style.all-styles": "All styles",
    "image-generation.select-style.all-techniques": "All techniques",
    "image-generation.generation.time.seconds": "sec",
    "image-generation.detalization": "Detalization",
    "image-generation.detalization.min": "Minimal",
    "image-generation.detalization.normal": "Normal",
    "image-generation.detalization.max": "Ultra",
    "image-generation.detalization-value.min": "Minimal",
    "image-generation.detalization-value.normal": "Normal",
    "image-generation.detalization-value.max": "Ultra",
    "image-generation.image-advanced-generation-mode.fast": "Faster",
    "image-generation.image-advanced-generation-mode.quality": "Better",
    "image-generation.image-advanced-generation-mode.ultra": "Ultra",
    "image-generation.mood": "Mood",
    "image-generation.mood.devilish": "Devilish",
    "image-generation.mood.evil": "Evil",
    "image-generation.mood.crazy": "Crazy",
    "image-generation.mood.dramatic": "Dramatic",
    "image-generation.mood.sad": "Sad",
    "image-generation.mood.mystical": "Mystical",
    "image-generation.mood.neutral": "Neutral",
    "image-generation.mood.heroic": "Heroic",
    "image-generation.mood.romantic": "Romantic",
    "image-generation.mood.joyful": "Joyful",
    "image-generation.mood.happy": "Happy",
    "image-generation.mood.error": "Select mood",
    "image-generation.age": "Age",
    "image-generation.age.stone-age": "Stone Age",
    "image-generation.age.bronze-age": "Bronze Age",
    "image-generation.age.iron-age": "Iron Age",
    "image-generation.age.antiquity": "Antiquity",
    "image-generation.age.middle-ages": "Middle Ages",
    "image-generation.age.renaissance": "Renaissance",
    "image-generation.age.industrialization": "Industrialization",
    "image-generation.age.modernity": "Modernity",
    "image-generation.age.future": "Future",
    "image-generation.age.set-year": "Set the year",
    "image-generation.realistic": "Realism",
    "image-generation.realistic.abstract": "abstract",
    "image-generation.realistic.realistic": "realistic",
    "image-generation.contrast": "Contrast",
    "image-generation.contrast.weak": "weak",
    "image-generation.contrast.strong": "strong",
    "image-generation.realistic-value.hyperabstract": "Abstract",
    "image-generation.realistic-value.abstract": "More abstract",
    "image-generation.realistic-value.styled": "Styled",
    "image-generation.realistic-value.realistic": "More realistic",
    "image-generation.realistic-value.hyperrealistic": "Realistic",
    "image-generation.selected-technique": "Selected technique:",
    "image-generation.technique": "Technique",
    "image-generation.technique.none": "None",
    "image-generation.color-scheme": "Color scheme",
    "image-generation.color-scheme-value.fullcolor": "Fullcolor",
    "image-generation.color-scheme-value.black-and-white": "Black and white",
    "image-generation.color-scheme-value.colors": "Custom colors:",
    "image-generation.model": "Model",
    "image-generation.mode": "Mode",
    "image-generation.negative-prompt": "Negative prompt",
    "image-generation.dynamic-composition": "Dynamic composition",
    "image-generation.advanced-generation-mode.info.1.title": "Fast",
    "image-generation.advanced-generation-mode.info.1.text": "Ideal for finding ideas and outlines, minimal token spending.",
    "image-generation.advanced-generation-mode.info.2.title": "Better",
    "image-generation.advanced-generation-mode.info.2.text": "Better quality and detailing, larger image sizes.",
    "image-generation.advanced-generation-mode.info.3.title": "Ultra",
    "image-generation.advanced-generation-mode.info.3.text": "Maximum image size and quality level.",
    "image-generation.generation.repeat-generation": "Repeat generation",
    "image-generation.generation.edit-generation": "Change details",
    "image-generation.generation.edit-generation.soon-text": "",
    "image-generation.generation.delete-generation": "Delete generation",
    "image-generation.generation.error": "Error in image generation",
    "image-generation.generation.error.image-generation-not-found": "Generation not found",
    "image-generation.generation.error.while-processing": "There was an error during generation. The tokens have been returned to your account.",
    "image-generation.generation.cant-start-processing-error": "Failed to start image generation",
    "image-generation.generation.variant-image.unzoom.start": "The image enlargement has been started. The result can be selected from the drop-down list",
    "image-generation.generation.variant-image.unzoom-mj.start": "Image enlargement is running. 4 expansion options can be selected from the drop-down list",
    "image-generation.generation.variant-image.unzoom.error": "An error occurred during image enhancement",
    "image-generation.generation.variant-image.unzoom.error.xl-model": "Unzoom cannot be applied to the selected model",
    "image-generation.generation.variant-image.unzoom.error.xl-model-basic": "Unzoom cannot be applied to the selected model. Select another style",
    "image-generation.generation.variant-image.unzoom.error.xl-model-advanced": "Unzoom cannot be applied to the selected model. Select another model ",
    "image-generation.generation.variant-image.unzoom.error.megapixel-limit": "Image size is too large",
    "image-generation.generation.variant-image.upscale.start": "Image enhancement has been started. The result can be selected from the drop-down list",
    "image-generation.generation.variant-image.upscale.error": "An error occurred during image enhancement",
    "image-generation.generation.variant-image.upscale.error.megapixel-limit": "Image size is too large",
    "image-generation.generation.variant-image.upscale.error.re-upscale-limit": "Re-upscale is not available at this time",
    "image-generation.generation.variant-image.nobg.start": "Image enhancement has been started. The result can be selected from the drop-down list",
    "image-generation.generation.variant-image.nobg.error": "An error occurred during delete background from image ",
    "image-generation.generation.variant-image.nobg.error.megapixel-limit": "Image size is too large",
    "image-generation.generated-image.cant-load-image-error": "Failed to load the image",
    "image-generation.generation.variant-image.original": "Original",
    "image-generation.generation.variant-image.upscale": "Upscale",
    "image-generation.generation.variant-image.unzoom": "Unzoom",
    "image-generation.generation.variant-image.nobg": "No background",
    "image-generation.generation.tooltip.not-enought-tokens": "Not enought tokens.",
    "image-generation.generation.tooltip.text": "Check out the",
    "image-generation.generation.tooltip.text.action": "tariff plans",
    "image-generation.generation.payments-modal.header-01": "Cost of generation:",
    "image-generation.generation.payments-modal.header-02": "Cost of unzoom:",
    "image-generation.generation.payments-modal.header-03": "Cost of upscale:",
    "image-generation.generation.payments-modal.header-04": "Cost of remove background:",
    "image-generation.generation.delete-generation-confirmation": "Delete generation?",
    "image-generation.generation.delete-generated-image-confirmation": "Delete image?",
    "image-generation.generation.delete-generated-image-with-child-generations-confirmation": "Delete original image and all childs?",
    "image-generation.generation.download.button-description": "Download",
    "image-generation.generation.delete.button-description": "Delete",
    "image-generation.generation.processing": "Processing...",
    "image-generation.generation.unzoom.processing": "Unzooming image...",
    "image-generation.generation.unzoom.title": "Expand an image",
    "image-generation.generation.unzoom.info": "If you need to add detail around your current image, thereby enlarging its overall area.",
    "image-generation.generation.unzoom.button-text": "Unzoom",
    "image-generation.generation.unzoom.button-description": "Unzoom",
    "image-generation.generation.upscale.processing": "Upscaling image...",
    "image-generation.generation.upscale.title": "Enhance an image",
    "image-generation.generation.upscale.info": "Refine fine details and correct flaws in faces, hands, etc. ",
    "image-generation.generation.upscale.button-text": "Upscale",
    "image-generation.generation.upscale.button-description": "Upscale",
    "image-generation.generation.upscale.strength": "Choose the degree of enhancement below:",
    "image-generation.generation.upscale.strength-description.min": "Minimum",
    "image-generation.generation.upscale.strength-description.normal": "Normal",
    "image-generation.generation.upscale.strength-description.max": "Ultra",
    "image-generation.generation.nobg.processing": "Deleting background...",
    "image-generation.generation.nobg.title": "Delete background",
    "image-generation.generation.nobg.info": "If you need to remove the background.",
    "image-generation.generation.nobg.button-text": "Delete background",
    "image-generation.generation.nobg.button-description": "Delete background",
    "image-generation.generation.default-prompt.1": "A detective in an alley at night.",
    "image-generation.generation.default-prompt.2": "A neon futuristic city.",
    "image-generation.generation.default-prompt.3": "A wizard casts a spell.",
    "image-generation.generation.default-prompt.4": "A sleuth by the sidewalk.",
    "image-generation.generation.default-prompt.5": "Flying cars in a metropolis.",
    "image-generation.generation.default-prompt.6": "A fairy over a flowering meadow.",
    "image-generation.generation.default-prompt.7": "A colony on another planet.",
    "image-generation.generation.default-prompt.8": "A mystical forest with runes.",
    "image-generation.generation.default-prompt.9": "Robots walking in the park.",
    "image-generation.generation.helper.description": "For a more accurate replication of the story, use the advanced settings",
    "generated-image.best-generated-images.title": "Generated images of community",
    "generated-image.results.results-will-be-shown-here": "Generated images will be here",
    "published-image.settings.style": "Style",
    "published-image.settings.prompt": "Prompt",
    "published-image.settings.negative-prompt": "Negative prompt",
    "published-image.settings.detalization": "Detalization",
    "published-image.settings.realistic": "Realistic",
    "published-image.settings.mood": "Mood",
    "published-image.settings.model": "Model",
    "published-image.settings.mode": "Mode",
    "published-image.settings.color-scheme": "Color scheme",
    "published-image.settings.creation-date": "Creation date",
    "published-image.settings.used-style-reference": "The reference style was applied in the original image",
    "published-image.settings.used-style-reference-2": "An additional image was used as a style reference",
    "published-image.settings.face-swap-tooltip": "Face swap",
    "published-image.settings.use-current-settings": "Repeat",
    "image-generation.generation.prompt-restriction": "We do not allow the generation of policy-related images",
    "published-image.delete-published-image-confirmation": "Delete publication?",
    "published-image.share-image.header": "Share",
    "published-image.share-image.description": "Send your image <br>to the community for moderation",
    "published-image.share-image.share-button-text": "Post",
    "published-image.share-image.share-socials": "or share in",
    "published-image.share-image.moderation-processing": "Your image has been submitted for moderation.",
    "published-image.share-image.moderation-accepted": "Your image has been accepted and published in the community.",
    "published-image.share-image.moderation-declined": "Your image has been rejected. <br>Reason: ",
    "published-image.share-image.moderation-declined.without-reason": "Your image has been rejected.",
    "characters.notofication.only-in-advanced-generation": "Characters are only available in advanced generations",
    "characters.new.title": "New character",
    "characters.new.name": "Name",
    "characters.new.gender": "Gender",
    "characters.new.gender.male": "Male",
    "characters.new.gender.female": "Female",
    "characters.new.age": "Age",
    "characters.new.type": "Type of character",
    "characters.new.type.human": "Human",
    "characters.new.type.robot": "Robot",
    "characters.new.type.cyborg": "Cyborg",
    "characters.new.type.elf": "Elf",
    "characters.new.type.dwarf": "Dwarf",
    "characters.new.type.troll": "Troll",
    "characters.new.type.orc": "Orc",
    "characters.new.type.demon": "Demon",
    "characters.new.type.custom": "Your own",
    "characters.new.hair": "Hair",
    "characters.new.hair.placeholder": "Dark and long",
    "characters.new.bald": "Bald",
    "characters.new.appearance": "Appearance",
    "characters.new.appearance.placeholder": "Describe special features, such as: facial features; clothing; jewelry",
    "characters.new.create-button": "Create a character",
    "characters.edit.title": "Edit a character",
    "characters.edit.save-button": "Save changes",
    "characters.edit.select-button": "Select",
    "characters.list.title": "My characters",
    "characters.list.add-new-button": "Create <br>a character",
    "characters.list.info.1": "The facial features of the character you create may change in each generation, but their appearance features will be retained.",
    "characters.list.info.2-1": "In prompt at one time can be used no more than",
    "characters.pluralize.unique": "{value, plural, zero {unique} one {unique} two {unique} few {unique} many {unique} other {unique}}",
    "characters.pluralize.characters": "{value, plural, zero {character} one {character} two {characters} few {characters} many {characters} other {characters}}",
    "characters.list.select": "Select",
    "characters.list.already-selected": "Already selected",
    "characters.list.limit-reached": "Limit reached",
    "characters.delete-confirmation.title": "Confirm action",
    "characters.delete-confirmation.text": "You really want to delete the character",
    "characters.delete-confirmation.delete-button": "Delete character",
    "characters.generation.error.cant-start-processing-error": "Failed to create a character",
    "characters.generation.error.error": "Character create error",
    "characters.copy.error": "Error when copying a character",
    "characters.generation.processing": "Generation...",
    "characters.card.edit.button-description": "Edit",
    "characters.card.copy.button-description": "Copy",
    "characters.card.delete.button-description": "Delete",
    "characters.generation.error.name-must-be-unique-error": "The character's name must be unique",
    "characters.generation.character-template-text": "Character",
    "characters.generation.character-select-text": "Choose a character",
    "characters.popup.create": "Create",
    "characters.not-existing.error.text-1": "No character information is available. Select from previously created characters or create a new one.",
    "characters.not-existing.error.action.select": "Select",
    "characters.not-existing.error.text-2": "from the previously created or",
    "characters.not-existing.error.action.create": "create a new one.",
    "characters.limit.error.text": "Maximize the number of characters in the prompt:",
    "characters.not-existing.error.notification": "No character information is available. Select from previously created characters or create a new one.",
    "characters.limit.error.notification": "Maximize the number of characters in the prompt:",
    "style-reference-upload-modal.title": "Upload your own image",
    "style-reference-upload-modal.description": "Use any image as a style to generate",
    "style-reference-upload-modal.style-repeatability.title": "Style repeatability",
    "style-reference-upload-modal.style-repeatability.min": "Minimum",
    "style-reference-upload-modal.style-repeatability.mid": "Medium",
    "style-reference-upload-modal.style-repeatability.max": "Maximum",
    "style-reference-upload-modal.apply-button": "Apply as a style",
    "style-reference-upload-modal.upload-button.text-1": "Drag and drop here",
    "style-reference-upload-modal.upload-button.text-2": "or",
    "style-reference-upload-modal.upload-button.text-2-upload": "upload",
    "style-reference-upload-modal.upload-button.text-3": "file",
    "style-reference-upload-modal.upload-another-button.text-1-upload": "Upload",
    "style-reference-upload-modal.upload-another-button.text-2": "another file",
    "image-generation.style-reference.without-image.title": "Use the style <br>from the image",
    "image-generation.style-reference.with-image.title": "Your style",
    "image-generation.style-reference.replace": "Replace",
    "image-generation.style-reference.upload.request.success": "The style was created successfully",
    "image-generation.style-reference.upload.request.error": "An error occurred while creating the style",
    "image-generation.style-reference.upload.request.error.file-limit": "File size is too large",
    "image-generation.style-reference.upload.request.error.file-extension": "A file of this type is not suitable",
    "image-generation.style-reference.upload.process-hint": "Style is being created",
    "image-generation.style-reference.upload.no-image-file.error": "No image file",
    "image-generation.maintenance.title": "Mode unavailable",
    "image-generation.maintenance.basic.description": "We are upgrading the service and some features may not be available.  <br><br>Please use the basic generation mode or try to open this page later.",
    "image-generation.maintenance.creative.description": "We are upgrading the service and some features may not be available.  <br><br>Please use the creative generation mode or try to open this page later.",
    "image-generation.maintenance.error": "We are upgrading the service and some features may not be available. Use another generation mode or try to open this page later",
    "image-generation.maintenance.action-basic": "Basic mode",
    "image-generation.maintenance.action-advanced": "Advanced mode",
    "image-generation.maintenance.action-creative": "Creative mode",
    "image-generation.selected-style.user-style-reference": "Style reference",
    "image-generation.form.error": "Please select all settings",
    "image-generation.form.prompt.min-length-limit.error": "Enter the prompt",
    "image-generation.form.prompt.max-length-limit.error": "The allowed number of characters in a prompt has been exceeded",
    "image-generation.form.negative-prompt.max-length-limit.error": "The allowed number of characters of a negative prompt has been exceeded",
    "image-generation.style-reference-not-compatible.error": "The selected generation mode is not compatible with its style",
    "image-generation.character-reference.modal.tab-1": "Face reference",
    "image-generation.character-reference.modal.tab-2": "Character reference",
    "image-generation.character-reference.modal.delete-button": "Delete",
    "image-generation.character-reference.modal.generation.title": "Upload or select a photo for face swap",
    "image-generation.character-reference.modal.generation.info": "If there are no faces in the image, the “Face Replacement” function will not work.",
    "image-generation.character-reference.modal.generation.face-swap-button": "Face swap",
    "image-generation.character-reference.modal.card.select-button": "Select",
    "image-generation.character-reference.preview-modal.title": "Frame the facial area",
    "image-generation.character-reference.preview-modal.save-button": "Save and continue",
    "image-generation.character-reference.upload.request.error": "An error occurred while loading the image",
    "image-generation.character-reference.upload.request.error.file-limit": "File size is too large",
    "image-generation.character-reference.upload.request.error.file-extension": "A file of this type is not suitable",
    "image-generation.character-reference.upload.process-hint": "The facial repetition style is created",
    "image-generation.character-reference.upload.no-image-file.error": "No image file",
    "image-generation.character-reference.upload.request.success": "Image uploaded",
    "image-generation.character-reference.delete-confirmation": "Delete the image?",
    "image-generation.generation.face-swap.prompt": "Face swap",
    "cover-editor.sidebar.cover-objects": "Cover elements",
    "cover-editor.sidebar.create-object": "Create",
    "cover-editor.sidebar.layers": "Layers",
    "cover-editor.sidebar.images.AI.caption": "AI generated",
    "cover-editor.sidebar.images.AI.description": "A neural network will help bring all your brilliant ideas to life",
    "cover-editor.sidebar.images.local.caption": "Upload image",
    "cover-editor.sidebar.images.local.description": "Upload existing file from your local storage",
    "cover-editor.sidebar.shapes": "Shapes",
    "cover-editor.sidebar.next": "Next",
    "cover-editor.toolbar.color": "Color",
    "cover-editor.toolbar.gradient": "Gradient:",
    "cover-editor.toolbar.stroke": "Stroke",
    "cover-editor.templates.caption.templates": "Templates",
    "cover-editor.object.create.header.caption": "Create block",
    "cover-editor.object.create.header.text": "Create cover element",
    "cover-editor.object.create.button.text": "Text blocks",
    "cover-editor.object.create.button.object": "Object",
    "cover-editor.object.create.button.image-ai": "AI images",
    "cover-editor.object.create.button.image-upload": "Upload image",
    "cover-editor.object.create.button.templates": "Templates",
    "cover-editor.object.create.object.header.caption": "Choose an object",
    "cover-editor.object.create.object.all": "all",
    "cover-editor.object.create.object.back": "back",
    "cover-editor.object.create.object.basic-shapes.header.caption": "Basic shapes",
    "cover-editor.object.create.object.age-constraints.header.caption": "Age constraints",
    "cover-editor.object.create.object.arrows.header.caption": "Arrows",
    "cover-editor.object.create.object.splashes.header.caption": "Splashes",
    "cover-editor.object.settings.alignment": "Alignment",
    "cover-editor.object.settings.color": "Color",
    "cover-editor.object.settings.gradient": "Gradient",
    "cover-editor.object.settings.text.header.caption": "Text block",
    "cover-editor.object.settings.text.font-family": "Font",
    "cover-editor.object.settings.text.style": "Style",
    "cover-editor.object.settings.text.align": "Alignment",
    "cover-editor.object.settings.text.fill": "Fill",
    "cover-editor.object.settings.shape.header.caption": "Object",
    "cover-editor.object.settings.shape.fill": "Fill",
    "cover-editor.object.settings.shape.stroke": "Stroke",
    "cover-editor.object.settings.shape.stroke-width": "Stroke width",
    "cover-editor.object.settings.shape.corner-radius": "Corner radius",
    "cover-editor.object.settings.line.line-type": "Line type",
    "cover-editor.object.settings.image.header.caption": "Image",
    "cover-editor.object.settings.multiselect.header.caption.selected": "",
    "cover-editor.object.settings.multiselect.header.caption.layers": "{objects, plural, zero {layers selected} one {layer selected} two {layers selected} few {layers selected} many {layers selected} other {layers selected} }",
    "cover-editor.object.settings.multiselect.button.group": "Group layers",
    "cover-editor.object.settings.group.header.caption": "Group",
    "cover-editor.object.settings.group.button.ungroup": "Ungroup layers",
    "cover-editor.object.settings.group.object-colors": "Colors used",
    "cover-editor.object.settings.group.object-tones": "Tones used",
    "cover-editor.object.settings.group.general-tone": "Set single color tone",
    "cover-editor.object.settings.group.button.apply-general-tone": "Apply",
    "cover-editor.object.settings.mask-group.header.caption": "Mask",
    "cover-editor.templates.header.caption": "Templates",
    "cover-editor.templates.button.save-current": "Save current",
    "cover-editor.templates.button.apply": "Apply",
    "cover-editor.templates.button.delete": "Delete",
    "cover-editor.sidebar.button.next": "Next",
    "cover-editor.completion-modal.export.caption": "Export",
    "cover-editor.completion-modal.export.description": "Download .png file",
    "cover-editor.completion-modal.export.button.download": "Download cover",
    "cover-editor.completion-modal.more.caption": "You can also",
    "cover-editor.completion-modal.more.description": "Keep working on you project",
    "cover-editor.completion-modal.more.button.upload-book": "Upload book",
    "cover-editor.completion-modal.more.button.edit-book": "Edit book",
    "cover-editor.completion-modal.more.button.to-my-projects": "To my projects",
    "cover-editor.completion-modal.more.button.to-cover-editor": "Back to cover",
    "cover-editor.conceptual-assistant.start-modal.title": "Conceptual Assistant",
    "cover-editor.conceptual-assistant.start-modal.description": "Enter your book's abstract and <br>get ideas for cover design",
    "cover-editor.conceptual-assistant.start-modal.annotation-placeholder": "My book is about.",
    "cover-editor.conceptual-assistant.start-modal.form.error": "Check the data entered",
    "cover-editor.conceptual-assistant.start-modal.form-min-number-characters.error": "Minimum number of characters",
    "cover-editor.conceptual-assistant.start-modal.form-max-number-characters.error": "Character limit reached",
    "cover-editor.conceptual-assistant.try-button": "Try",
    "cover-editor.conceptual-assistant.view-button": "Посмотреть",
    "cover-editor.conceptual-assistant.title": "Conceptual <br>assistant",
    "cover-editor.conceptual-assistant.new.description": "Ideas for your <br>cover from Neural Net",
    "cover-editor.conceptual-assistant.view.description": "Your cover <br>idea is ready!",
    "cover-editor.conceptual-assistant.menu.title": "Conceptual assistant",
    "cover-editor.conceptual-assistant.menu.annotation": "Annotation",
    "cover-editor.conceptual-assistant.menu.annotation-placeholder": "Enter an abstract of the project",
    "cover-editor.conceptual-assistant.menu.target-audience": "Annotation",
    "cover-editor.conceptual-assistant.menu.target-audience-placeholder": "Enter an target audience of the project",
    "cover-editor.conceptual-assistant.menu.genre": "Genre",
    "cover-editor.conceptual-assistant.menu.genre-placeholder": "Enter an genre of the project",
    "cover-editor.conceptual-assistant.menu.generate-button": "Generate ideas",
    "cover-editor.conceptual-assistant.result.generation-error": "Error generating cover concept",
    "cover-editor.conceptual-assistant.result.theme.title": "Theme ",
    "cover-editor.conceptual-assistant.result.genre.title": "Genre",
    "cover-editor.conceptual-assistant.result.target-audience.title": "Target audience",
    "cover-editor.conceptual-assistant.result.prompt.title": "Choose a prompt",
    "cover-editor.conceptual-assistant.result.prompt.image-style.title": "Choose a style",
    "cover-editor.conceptual-assistant.result.prompt.image-mood.title": "Mood",
    "cover-editor.conceptual-assistant.result.prompt.image-age.title": "Age",
    "cover-editor.conceptual-assistant.result.prompt.image-style.color.background": "Background color",
    "cover-editor.conceptual-assistant.result.prompt.image-style.color.accent": "Accent color",
    "cover-editor.conceptual-assistant.result.prompt.action-button": "Generate",
    "cover-editor.conceptual-assistant.result.fonts-and-colors.title": "Fonts and colors",
    "cover-editor.conceptual-assistant.result.font.style.main": "Main font",
    "cover-editor.conceptual-assistant.result.font.style.subheading": "Subheading font",
    "cover-editor.conceptual-assistant.result.font.style.secondary": "Secondary font",
    "cover-editor.conceptual-assistant.result.fonts-and-colors.action-button": "Apply",
    "cover-editor.conceptual-assistant.result.summary.title": "Summary",
    "cover-editor.conceptual-assistant.result.reset-button": "New idea",
    "cover-editor.conceptual-assistant.result.refresh-button": "More ideas",
    "cover-editor.conceptual-assistant.result.processing": "Idea generation...",
    "cover-editor.share.cover-published-notification": "Cover has been sent to moderation and will be published shortly",
    "cover-editor.image.remove-background": "Remove the background from an image",
    "cover-editor.image.remove-background.info": "Removes the background from the image and adds it as a new layer",
    "cover-editor.image.removed-background.text": "(no background)",
    "cover-editor.cover.error-on-save": "There is error while saving image",
    "cover-editor.fonts.fonts-loading": "Loading...",
    "cover-editor.position": "Position",
    "cover-editor.conceptual-assistant.info-block.step-1.title": "Cover design ideas <br>are ready to go!",
    "cover-editor.conceptual-assistant.info-block.step-1.description": "Follow my <br>recommendations <br>for great <br>results.",
    "cover-editor.sidebar.tabs.service": "Service",
    "cover-editor.sidebar.tabs.community": "Community",
    "cover-editor.object.snippet.title": "Snippet",
    "cover-editor.object.settings.group.button.create-snippet": "Create a snippet",
    "cover-editor.create-mask": "Create mask",
    "cover-editor.delete-mask": "Delete mask",
    "cover-editor.mask": "Mask",
    "cover-editor.mask.add-elements": "Add objects",
    "cover-editor.mask.mask-element": "Mask element",
    "cover-editor.create-snippet-modal.title": "Add an asset to the community",
    "cover-editor.create-snippet-modal.category.title": "Type",
    "cover-editor.create-snippet-modal.category.text": "Text",
    "cover-editor.create-snippet-modal.category.object": "Object",
    "cover-editor.create-snippet-modal.subcategory.title": "View",
    "cover-editor.cover-snippet.subcategory.text.headers-and-text.title": "Headings and text",
    "cover-editor.cover-snippet.subcategory.text.lists.title": "Lists",
    "cover-editor.cover-snippet.subcategory.text.quotes.title": "Quotes",
    "cover-editor.cover-snippet.subcategory.objects.basic-shapes.title": "Basic shapes",
    "cover-editor.cover-snippet.subcategory.objects.age-constraints.title": "Age constraints",
    "cover-editor.cover-snippet.subcategory.objects.arrows.title": "Arrows",
    "cover-editor.cover-snippet.subcategory.objects.splashes.title": "Splashes",
    "cover-editor.cover-snippet.default.text.title": "Text",
    "cover-editor.cover-snippet.default.image.title": "Image",
    "cover-editor.cover-snippet.default.rectangle.title": "Rectangle",
    "cover-editor.cover-snippet.default.ellipse.title": "Ellipse",
    "cover-editor.cover-snippet.default.shape.title": "Shape",
    "cover-editor.cover-snippet.default.group.title": "Group",
    "cover-editor.cover-snippet.default.snippet.title": "Snippet",
    "cover-editor.create-snippet-modal.is-premium.title": "Premium",
    "cover-editor.create-snippet-modal.is-visible-to-users.title": "Available to the user",
    "cover-editor.create-snippet-modal.is-visible-to-other-companies.title": "Available to other companies",
    "cover-editor.create-snippet-modal.send-for-moderation.button": "Send for moderation",
    "cover-editor.create-snippet-modal.form.error.notification": "Fill in the blanks",
    "cover-editor.create-snippet-modal.create.error.notification": "Error creating a snippet",
    "cover-editor.create-snippet-modal.apply.error.notification": "An error occurred while adding a snippet",
    "cover-editor.conceptual-assistant-step-2.genre.without-genre": "No genre",
    "cover-editor.conceptual-assistant-step-2.genre.fantastic": "Fantastic",
    "cover-editor.conceptual-assistant-step-2.genre.detective": "Detective",
    "cover-editor.conceptual-assistant-step-2.genre.horror": "Horror",
    "cover-editor.conceptual-assistant-step-2.genre.comedy": "Comedy",
    "cover-editor.conceptual-assistant-step-2.genre.tragedy": "Tragedy",
    "cover-editor.conceptual-assistant-step-2.genre.romance": "Romance",
    "cover-editor.conceptual-assistant-step-2.genre.adventure": "Adventure",
    "cover-editor.conceptual-assistant-step-2.genre.mysticism": "Mysticism",
    "cover-editor.conceptual-assistant-step-2.genre.historical-prose": "Historical Prose",
    "cover-editor.conceptual-assistant-step-2.genre.social-prose": "Social Prose",
    "cover-editor.conceptual-assistant-step-2.genre.fantasy": "Fantasy",
    "cover-editor.conceptual-assistant-step-2.genre.drama": "Drama",
    "home.breadcrumb.home-page": "Main page",
    "home.last-project.new-project": "New project",
    "home.last-project.last-project": "Last project",
    "home.menu.my-projects-button": "My projects",
    "home.menu.create-cover-button": "Create a cover",
    "home.menu.image-generation-button": "AI images",
    "home.menu.check-text-button": "Check text",
    "home.menu.referals-button": "Referals",
    "home.menu.feedback-button": "Leave feedback",
    "home.menu.knowledge-base-button": "Knowledge base",
    "home.menu.image-editor-button": "Photo editor",
    "home.menu.image-enhancement-button": "Picture enhancement",
    "home.menu.background-removal-button": "Background removal",
    "home.menu.platform-covers-button": "Social networks design",
    "home.menu.community-contests-button": "Contests",
    "home.menu.funbums-button": "Create funbum",
    "home.unit": "mm",
    "home.new-project-modal.title": "New project",
    "home.new-project-modal.sidebar-section.book": "Book",
    "home.new-project-modal.sidebar-section.item.book.format-a4": "Format A4",
    "home.new-project-modal.sidebar-section.item.book.format-a5": "Format A5",
    "home.new-project-modal.sidebar-section.item.book.format-custom": "Custom format",
    "home.new-project-modal.sidebar-section.corporate-material": "Corporate material",
    "home.new-project-modal.sidebar-section.item.corporate-material.letter": "Letter",
    "home.new-project-modal.sidebar-section.item.corporate-material.report": "Report",
    "home.new-project-modal.sidebar-section.item.corporate-material.contract": "Contract",
    "home.new-project-modal.sidebar-section.scientific-publication": "Scientific publication",
    "home.new-project-modal.upload-template": "Upload your template",
    "home.new-project-modal.content.header.books": "Books",
    "home.new-project-modal.content.header.corporate-material": "Corporate material",
    "home.new-project-modal.content.header.scientific-publication": "Scientific publication",
    "home.new-project-modal.content.header.format": "Format",
    "home.community.title": "Community",
    "home.community.filter.published-covers": "Covers",
    "home.community.filter.published-images": "Images",
    "home.community.create-cover.button": "Create a cover",
    "home.community.generate-images.button": "Generate images",
    "home.community.unpublish-modal": "Unpublish?",
    "home.community.unpublish-confirm-notification": "Unpublished",
    "home.community.empty-state-images": "Generated images will be here",
    "home.community.empty-state-covers": "Community covers will be here",
    "home.promo-menu-buttons.title": "Start creating today",
    "home.promo-menu-buttons.description": "A wide range of tools for every task",
    "home.promo-menu-buttons.button.participate-webinar": "Participate <br>in the webinar!",
    "home.promo-menu-buttons.button.create-book": "Layout <br>books",
    "home.promo-menu-buttons.button.create-image-generation": "Generate <br>images",
    "home.promo-menu-buttons.button.create-cover-idea": "Create <br>cover <br>ideas",
    "home.promo-menu-buttons.button.create-cover": "Create <br>covers",
    "home.promo-menu-buttons.button.upgrade-image": "Enhance <br>images",
    "home.promo-menu-buttons.button.get-tokens": "Get activity <br>tokens",
    "home.select-new-project-type.title": "Where do we start?",
    "home.select-new-project-type.create-cover.title": "Create a cover",
    "home.select-new-project-type.create-cover.description": "Neural network will help you <br>realize all your brilliant ideas",
    "home.select-new-project-type.create-book.title": "Design a book",
    "home.select-new-project-type.create-book.description": "Create a printed masterpiece <br>from your manuscript"
  }
};